import { AnyAction } from "redux";
import actions from "../constants/actions.json";


const initialState: bb.state.IAccount = {
  subscriptions: [],
  invoices: [],
  planInfo: [],
  upcomingInvoice: null,
  bundleSubscription: false,
  eligibleRenewal: {
    bb2: false,
    bb3: false,
    bb4: false,
    bbbundle: false,
    soundbox: false,
  },
  planProviders: {
    bb2: null,
    bb3: null,
    bb4: null,
    bbbundle: null,
    soundbox: null,
  },
  plans: {
    bb2: null,
    bb3: null,
    bb4: null,
    bbbundle: null,
    soundbox: null,
  },
};

const accountReducer = (
  state: bb.state.IAccount = initialState,
  action: AnyAction
): bb.state.IAccount => {
  switch (action.type) {
    case actions.account.SET_CURRENT_PLANS:
      return {
        ...state,
        subscriptions: [...action.plans],
      };
    case actions.account.SET_INVOICES:
      return {
        ...state,
        invoices: [...action.invoices],
      };
    case actions.account.SET_UPCOMING_INVOICE:
      return {
        ...state,
        upcomingInvoice: action.upcomingInvoice
      };
    case actions.account.SET_BUNDLE_SUBSCRIPTION:
      return {
        ...state,
        bundleSubscription: action.bundleSubscription
      };
    case actions.account.SET_ELIGIBLE_RENEWAL:
      return {
        ...state,
        eligibleRenewal: action.eligibleRenewal
      }
    case actions.account.CLEAR_ACCOUNT:
      return initialState;
    case actions.account.SET_PLAN_PROVIDERS:
      return {
        ...state,
        planProviders: action.planProviders
      }
    case actions.account.SET_USER_PLANS:
      return {
        ...state,
        plans: action.plans
      }
    default:
      return state;
  }
};

export default accountReducer;
