
import TagManager from "react-gtm-module";
import Env from "../Env";

export type AnalyticDataDTO = {
  UserID: string;
  genai_question: string;
  genai_user_response: string;
  genai_skipped: boolean;
}

export const customEventDataLayerTrigger = (eventName: string, userId: string, otherData: Partial<AnalyticDataDTO> = {}) => {
  const prePendEventName = Env.AOB_ENV.toUpperCase() === "PROD" ? "" :  Env.AOB_ENV.toUpperCase() + "_";

  const customEventName =  `${prePendEventName}${eventName}`;

  console.log("customEventDataLayerTrigger", customEventName, userId, otherData);
  TagManager.dataLayer({
    dataLayer: {
        event: customEventName,
        UserId: userId,
        ...otherData
    },
  });

}