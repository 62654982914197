import { BBVersions } from "../reducers/models";

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function formatUNIXTimestamp(timestamp, hyperWalletStyle) {
  if (timestamp) {
    const dateObject = new Date(timestamp);
    let month = dateObject.toLocaleString("en-US", { month: "short" });
    let day = dateObject.toLocaleString("en-US", { day: "numeric" });
    let year = dateObject.toLocaleString("en-US", { year: "numeric" });
    if (hyperWalletStyle) {
      month = dateObject.toLocaleString("en-US", { month: "2-digit" });
      day = dateObject.toLocaleString("en-US", { day: "2-digit" });
      year = dateObject.toLocaleString("en-US", { year: "numeric" });

      return `${year}-${month}-${day}`;
    }
    return `${month} ${day}, ${year}`;
  }
  return "";
}

// I don't THINK BB Bundle makes sense in here, but I'm not sure. seems mostly application-specific and bundle would be out of place
export function getBBVersion(productType) {
  if(productType) {
    const productTypeLowerCase = productType.toLowerCase();
    switch (productTypeLowerCase) {
      case "bb2":
      case "buildbox classic":
        return BBVersions.BUILDBOX2;
      case "bb3":
      case "buildbox 3":
        return BBVersions.BUILDBOX3;
      case "bb4":
      case "buildbox 4":
        return BBVersions.BUILDBOX4;
      case "bbworld":
        return BBVersions.BUILDBOXWORLD;
      case "soundbox":
        return BBVersions.SOUNDBOX;
      case "generic":
        return BBVersions.GENERIC;
      default:
        return BBVersions.GENERIC;
    }
  }
  else {
    console.log("product type not set, defaulting to generic")
    return BBVersions.GENERIC;
  }
}

export function openNewWindow(url, writeToWindow) {
  var y = window.outerHeight / 2 + window.screenY - 500 / 2;
  var x = window.outerWidth / 2 + window.screenX - 500 / 2;
  let newwindow = window.open(
    url,
    "",
    `height=1000,width=1000, top=${y},left=${x}`
  );
  if (window.focus) {
    if (writeToWindow) {
      newwindow.document.write(
        `
        <iframe src="https://docs.google.com/gview?url=${url}&embedded=true" style="width:1000px; height:1000px;" frameborder="0"></iframe>
  `
      );
    }
    newwindow.focus();
  }
  return false;
}
