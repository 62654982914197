import { PlanType, WorkState } from "./../reducers/models";
import Log from "../utils/Log";
import actions from "../constants/actions.json";
import { push } from "connected-react-router";
import { DefaultThunkAction } from "./shared";
import { AnyAction } from "redux";
import routes from "../constants/routes.json";
import {
  redirectToPreferredPaymentPlatformForProduct,
  redirectToPreviousPage,
} from "../utils/routes";
import { LoginClientType, LoginState, ProductType } from "../reducers/models";
import { cognitoLogin } from "../utils/CognitoUtils";
import { createUser } from "../api/auth";
import { findCookieValue } from "../utils/referralUtils";

//used to login users into Buildbox client by opening s3 page to intiate websocket token transfer

export function clientLoginRedirect(
  username?: string,
  password?: string,
  proposedBuildboxUserId?: string,
  oneTimeCode?: string,
  isGuest?: boolean
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const { router } = getState();

    const currentUrl = router.location;

    try {
      //manual client login
      if (username && password) {
        const { AccessToken, RefreshToken } = await cognitoLogin(
          username,
          password
        );

        //if we detect a referral cookie on login include it for user creation
        const xsollaTrackingId = findCookieValue("tracking_id=");
        let ReferralInfo = null;
        if (xsollaTrackingId) {
          ReferralInfo = {
            Network: "Xsolla",
            Info: xsollaTrackingId,
          };
        }
        const response = await createUser(AccessToken, ReferralInfo, proposedBuildboxUserId, oneTimeCode, isGuest);
        const { Email } = response.payload.user;

        sessionStorage.setItem("websocket-accessToken", AccessToken);
        sessionStorage.setItem("websocket-refreshToken", RefreshToken);
        sessionStorage.setItem("websocket-email", Email);
        sessionStorage.setItem("websocket-username", username);
      }

      const reactDomain = process.env.REACT_APP_DOMAIN_URL;
      const websocketDomain = process.env.REACT_APP_S3_WEBSOCKET_DOMAIN;
      const websocketPage = process.env.REACT_APP_S3_WEBSOCKET_PAGE;

      //   const urlQuery = QueryString.parse(currentUrl.search);
      const urlQuery = currentUrl.search;

      let websocketPort;
      if (urlQuery["port"]) {
        websocketPort = urlQuery["port"];
      } else {
        websocketPort = sessionStorage.getItem("websocketPort");
      }

      let showLinkback = "0";
      let provider = sessionStorage.getItem("websocket-provider");
      if(provider === "Google" || provider === "Facebook" || provider === "SignInWithApple") { // social sign in may need deeplink fallback
        console.log("will show linkbacks");
        showLinkback = "1";
      }

      const destUrl =
        websocketDomain +
        "/" +
        websocketPage +
        "?port=" +
        websocketPort +
        "&d=" +
        reactDomain +
        "&lb=" + 
        showLinkback +
        "&n=" + Date.now();
      Log.trace("dest url: ", destUrl);
      //Log.trace(sessionStorage, "sessionStorage");
      //redirects to s3 client login redirect page with iframe component /clientpostmessageconnection
      window.open(destUrl, "_self");
    } catch (e:any) {
      Log.error(e, "caught error in clientLoginRedirect");
      throw new Error(e.message);
    }
  };
}

// Website user login redirect

export function webLoginRedirect(
  props: any
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    // first casting to unknown because I could not figure out the right Thunk type parameter to use to get getState to have a return type
    const { cart, auth } = (getState() as unknown as {cart:bb.state.ICart, auth:bb.state.IAuth});

    const afterSignupRedirect = sessionStorage.getItem("afterSignupRedirect");
    const planType = sessionStorage.getItem("planType");
    const isDeprecated = sessionStorage.getItem("isDeprecated");

    if (isDeprecated === "true") {
      console.log("not redirecting deprecated plan")
      return;
    }

    if(afterSignupRedirect) {
      sessionStorage.removeItem("afterSignupRedirect");
      if(afterSignupRedirect.toLowerCase() === "thankyoupageafterpurchase") {
        dispatch(push(routes.THANK_YOU_PURCHASE));
      }
      else {
        redirectToPreviousPage(props);
      }
    }
    else if (planType) {
      //redirecting users to download page when clicking "Download Now" on /plans page
      if (planType.toLowerCase() === "free") {
        const cartItem = cart.items[0].item;

        if (cartItem.planType === PlanType.Free) {
          dispatch(push(cartItem.downloadUrl));
        }
      } 
      else if (planType.toLowerCase() === "thankyoupage") {
        dispatch(push(routes.THANK_YOU));
      } 
      else {
        // AP: will I need a redirect entry around here about 'choose payment platform'?
        // AP: I am not confident in this bit. how do we get to this state and what can we expect to happen?
        const promoCode = sessionStorage.getItem("promoCode");
        if (cart && cart.items && cart.items.length > 0) {
          const cartItem = cart.items[0].item;
          const productName = cartItem.productType;
          //redirectToCheckout(props, promoCode);

          // AP: had to hack this 'any' in here because it was coming up as a new typescript error,
          //  routes is a plain .js file so I guess we need a .d or something
          let referralInfo : any = null
          if(auth?.user?.referralInfo) {
            referralInfo = auth?.user?.referralInfo
          }
          redirectToPreferredPaymentPlatformForProduct(
            props,
            productName,
            referralInfo,
            promoCode
          );
        } else {
          // TODO: this is probably an error situation?
          console.error("plan type set with no plan info available");
          redirectToPreviousPage(props);
        }
      }
    } else {
      redirectToPreviousPage(props);
    }
  };
}

export function setPlanFetchWorkState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_PLAN_FETCH_STATE,
    workState,
  };
}
export function setBillingInfoFetchWorkState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_BILLING_INFO_FETCH_STATE,
    workState,
  };
}
export function setInvoicesFetchWorkState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_INVOICES_FETCH_STATE,
    workState,
  };
}
export function setPlanInfoFetchWorkState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_PLAN_INFO_FETCH_STATE,
    workState,
  };
}
export function setLoginWorkState(loginState: LoginState): AnyAction {
  return {
    type: actions.current.SET_LOGIN_STATE,
    loginState,
  };
}
export function setPurchaseState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_PURCHASE_STATE,
    workState,
  };
}

export function setSignUpWorkState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_SIGNUP_STATE,
    workState,
  };
}
export function setLoggingInfromClient(client: LoginClientType): AnyAction {
  return {
    type: actions.current.SET_LOGIN_TYPE,
    client,
  };
}

export function setFromSSORedirect(fromSSORedirect: boolean): AnyAction {
  return {
    type: actions.current.SET_FROM_SSO_REDIRECT,
    fromSSORedirect,
  };
}

export function resetCurrentState(): AnyAction {
  return {
    type: actions.current.RESET_CURRENT_STATE,
  };
}

export function setAnalyticsWorkState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_ANALYTICS_WORKSTATE,
    workState,
  };
}

export function setAdSettingsWorkState(
  adSettingsWorkState: WorkState
): AnyAction {
  return {
    type: actions.current.SET_AD_SETTINGS_WORKSTATE,
    adSettingsWorkState,
  };
}

export function setProductType(productType: ProductType | string): AnyAction {
  return {
    type: actions.current.SET_PRODUCT_TYPE,
    productType,
  };
}

export function setTransactionsFetchWorkState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_TRANSACTIONS_FETCH_WORKSTATE,
    workState,
  };
}

export function setProjectsFetchWorkState(workState: WorkState): AnyAction {
  return {
    type: actions.current.SET_PROJECTS_FETCH_WORKSTATE,
    workState,
  };
}
