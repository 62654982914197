import { WorkState } from "../reducers/models";
import { AnyAction } from "redux";
import actions from "../constants/actions.json";
import PLANS_JSON from "../constants/planMetadata";

const initialState: bb.state.ISale = {
  houseAdCampaign: {
    website_homepage: null,
    website_pricing: null,
    website_stripe: null
  },
  saleData: {},
  saleContext: {
    showSaleUI: false,
    showSalesLoader: false,
    discountedCost: PLANS_JSON, //discounted copy of plans json
    totalCost: null,
    saleJustEnded: false,
  },
  workState: {
    saleState: WorkState.None,
  },
};

const saleReducer = (
  state: bb.state.ISale = initialState,
  action: AnyAction
): bb.state.ISale => {
  switch (action.type) {
    case actions.sale.SET_SALE_STATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          saleState: action.saleState,
        },
      };
    case actions.sale.SET_SALE_DATA:
      return {
        ...state,
        saleData: Object.assign({}, { ...action.saleData }),
      };
    case actions.sale.SET_SALE_UI:
      return {
        ...state,
        saleContext: {
          ...state.saleContext,
          showSaleUI: action.showSaleUI,
        },
      };
    case actions.sale.SET_SALE_LOADER:
      return {
        ...state,
        saleContext: {
          ...state.saleContext,
          showSalesLoader: action.showSalesLoader,
        },
      };
    case actions.sale.SET_DISCOUNTED_COST:
      return {
        ...state,
        saleContext: {
          ...state.saleContext,
          discountedCost: action.discountedCost,
        },
      };
    case actions.sale.SET_TOTAL_COST:
      return {
        ...state,
        saleContext: {
          ...state.saleContext,
          totalCost: action.totalCost,
        },
      };
    case actions.sale.RESET_SALE_STATE:
      return {
        ...state,
        saleData: {},
        saleContext: {
          ...state.saleContext,
          showSaleUI: false,
          showSalesLoader: false,
        },
      };
    case actions.sale.SET_SALE_JUST_ENDED:
      return {
        ...state,
        saleContext: {
          ...state.saleContext,
          saleJustEnded: action.saleJustEnded,
        },
      };
    case actions.sale.CLEAR_SALE_DATA:
      return initialState;
    case actions.sale.SET_HOUSE_AD_CAMPAIGN_HOMEPAGE:
      const houseAdCampaignForHomepage = action.houseAdCampaign !== null? {...action.houseAdCampaign} : null
      return {
        ...state,
        houseAdCampaign: {
          ...state.houseAdCampaign,
          website_homepage: houseAdCampaignForHomepage
        }
      }
    case actions.sale.SET_HOUSE_AD_CAMPAIGN_PRICING:
      const houseAdCampaignForPricing = action.houseAdCampaign !== null? {...action.houseAdCampaign} : null
      return {
        ...state,
        houseAdCampaign: {
          ...state.houseAdCampaign,
          website_pricing: houseAdCampaignForPricing
        }
      }
    case actions.sale.SET_HOUSE_AD_CAMPAIGN_STRIPE:
      const houseAdCampaignForStripe = action.houseAdCampaign !== null? {...action.houseAdCampaign} : null
      return {
        ...state,
        houseAdCampaign: {
          ...state.houseAdCampaign,
          website_stripe: houseAdCampaignForStripe
        }
      }
    default:
      return state;
  }
};

export default saleReducer;
