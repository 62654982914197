import API from "@aws-amplify/api";
import Log from "../utils/Log";

//NK: We decided to keep this in case we want to implement Xsolla referral program in the future
/**
 * fires a referral signup event
 *
 * @param {trackingId}: tracking id string from the referrer
 * @param {userId}: uuid of the signed up user in our server
 */

export const sendReferralTrackingId = (trackingId, userId) => {
  return new Promise((resolve, reject) => {
    API.post("assetsURL", "/assetmanagement/referralEvent", {
      body: {
        trackingId: trackingId,
        userId: userId,
      },
      headers: {
        "Content-Type": "application/json",
        Token: "", //TODO: add access token here
      },
      response: true,
    })
      .then((res) => {
        console.log("res ======>", res);
        Log.info(res.data, "Referral Signup Credited:");
        resolve(res);
      })
      .catch((e) => {
        Log.info(e.response?.data?.metadata?.message, "Non-referral signup:");
        if (e.response && e.response.status === 422) {
          resolve(e.response.data);
        } else {
          reject(e);
        }
      });
  });
};
