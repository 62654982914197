import React, { useContext, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Log from "../utils/Log";
import { logout } from "../actions/auth";
import { showError } from "../actions/error";
import currentUserInfo from "../selectors/currentUserInfo";
import styles from "../styles/Header.module.scss";
import imgBuildboxLogo from "../img/LogoBB_Normal.png";
import imgBuildbox4Logo from "../img/BB4_Logo_Wordmark_Wht.png";
import routes from "../constants/routes.json";
import classnames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

import {HeaderItem, headerItems, profileHeaderItems} from "../constants/HeaderItems";
import { MenuItem } from "../components/MenuItem";
import setCssVariables from "../utils/setCssVariables";
import { SocialIcons } from "../components/SocialIcons";
import useIsMobile from "../hooks/useIsMobile";
import { addPlanToCart } from "../actions/cart";
import { PlanIds } from "../reducers/models";

// controls the "Try Buildbox X" button in upper right
const defaultHomepage = process.env.REACT_APP_MAIN_HOME_PAGE;

export default function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(currentUserInfo);
  const { session } = useContext(UserContext);
  const {isMobile} = useIsMobile()
  const history = useHistory()
  
  const [menuOpenState, toggleMenuState] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const color1 = sessionStorage.getItem("profileColor1");
  const color2 = sessionStorage.getItem("profileColor2");

  async function onLoad() {
    try {
      if (currentUser) {
        // Log.trace("got current user!", currentUser);
      }

      setActiveTab(location.pathname);
    } catch (e) {
      console.log("e", e);
    }
  }

  useEffect(() => {
    onLoad(); //eslint-disable-next-line
  }, [location.pathname, currentUser, session]);

  function handleSignIn() {
    console.log("clicked");

    try {
      // save path name to redirect after successful login
      sessionStorage.setItem("previousRoute", location.pathname + location.search);

      // redirectToLoginPage(props);
    } catch (e) {
      Log.error(e, "Header component: error signing in");
    }
  }

  async function handleLogout() {
    try {
      await dispatch(logout());
    } catch (e: any) {
      Log.error(e, "App Component: error caught logging out");
      dispatch(showError(e.message));
    }
  }

  function renderAuthState() {
    // if (workState.loginState === LoginState.Loading) {
    //   return (
    //     <div className={styles.signingInLoaderContainer}>
    //       <img
    //         className={styles.loadingGif}
    //         src="https://frontend-assets.buildbox.com/bb_logo_new_morph_black.gif"
    //         alt={"Buildbox Logo"}
    //       />
    //       <p style={{ padding: "0.5em", color: "white" }}>SIGNING IN...</p>
    //     </div>
    //   );
    // }

    if (currentUser && currentUser.username) {
      const userNameLetter = currentUser.name.charAt(0).toUpperCase();
      const background = {
        "background": `linear-gradient(to bottom, ${color1}, ${color2})`,
      };
      setCssVariables(background);
      return (
        <>
          <p
            className={styles.profileBtn}
            style={background}
            onClick={() => setToggleDropdown(!toggleDropdown)}
          >
            {userNameLetter}
          </p>
          {toggleDropdown && (
            <div className={styles.profileDropdown}>
              <ul>
                <NavLink
                  to="/account/profile"
                  activeClassName={styles.selected}
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                >
                  <span>Profile</span>
                </NavLink>
                <NavLink
                  to="/account/subscription"
                  activeClassName={styles.selected}
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                >
                  <span>Device Management</span>
                </NavLink>
                <NavLink
                  to="/account/ads/earnings"
                  activeClassName={styles.selected}
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                >
                  <span>Adbox</span>
                </NavLink>
                <NavLink
                  to="/account/support"
                  activeClassName={styles.selected}
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                >
                  <span>Support</span>
                </NavLink>
                <li>
                  <p onClick={handleLogout}>Sign out</p>
                </li>
              </ul>
            </div>
          )}
        </>
      );
    } else {
      return (
        <React.Fragment>
          <Link className={classnames(styles.signInContainer, styles.button)} to={routes.LOGIN} onClick={handleSignIn}>
              Sign In
          </Link>
        </React.Fragment>
      );
    }
  }

  function getMobileMenu() {
    const animStyle = {
      overflow: "hidden",
      transition: "max-height 0.25s cubic-bezier(0.65, 0.05, 0.36, 1) 0s",
      height: "height: calc( 100vh - 100px )",
      maxHeight: "0px",
    };

    const linkHeaderItems = {
      gridTemplateRows: `repeat( ${headerItems.length}, 35px )`,
    };

    if (menuOpenState) {
      animStyle.maxHeight = "100vh"; // Value doesn't seem to really matter, as long as its not auto and larger than content height
    }

    return (
      <div
        onClick={toggleMenu}
        className={styles.linkListMobile}
        style={animStyle}
      >
        <div className={styles.listContainer} style={linkHeaderItems}>
          {renderMenuItems(headerItems)}
          {renderMobileAuth()}
        </div>
      </div>
    );
  }

  function renderMenuItems(headerItemsArray: HeaderItem[]) {
    return headerItemsArray.map((headerItem) => {
      if(headerItem.title === "Downloads" && isMobile) return null
      return (
      <MenuItem
        key={headerItem.title}
        menuItem={headerItem}
        activeTab={activeTab}
      />
    )});
  }

  function renderMobileAuth() {
    if (currentUser && currentUser.username) {
      return (
        <>
          <>{renderMenuItems(profileHeaderItems)}</>
          <div className={styles.menuTitleContainer}>
            <p onClick={handleLogout} className={styles.parentMenuTitle}>
              Sign out
            </p>
          </div>
        </>
      );
    } else {
      return (
        <Link
          to={routes.LOGIN}
          onClick={handleSignIn}
          className={styles.loginNavLink}
        >
          <div className={styles.signInWrapper}>
            <p className={styles.parentMenuTitle}>Sign In</p>
          </div>
        </Link>
      );
    }
  }

  function toggleMenu() {
    toggleMenuState(!menuOpenState);
  }


  function handlePurchaseClick(): void {

    if(defaultHomepage === "BBCLASSIC") {
      dispatch(addPlanToCart(PlanIds.BB2Beginner));
    }
    else {
      dispatch(addPlanToCart(PlanIds.BB4Beginner));
    }

    const promoCode = sessionStorage.getItem("promoCode");
    if (currentUser?.referralInfo?.Network?.toLowerCase() === "xsolla") {
      if (promoCode) {
        history.push(`/purchaseThroughXsolla?promo=${promoCode}`);
      } else {
        history.push("/purchaseThroughXsolla");
      }
    } else {
      if (promoCode) {
        history.push(`/purchaseThroughStripeCheckout?promo=${promoCode}`);
      } else {
        history.push("/purchaseThroughStripeCheckout");
      }
    }
  }

  function logoElement() {
    let logoUrl
    if(defaultHomepage === "BBCLASSIC") {
      logoUrl = imgBuildboxLogo
    }
    else {
      logoUrl = imgBuildbox4Logo
    }
    return (
      <a className={styles.logoWrapper} href="/">
        <img
          src={logoUrl}
          alt="logo"
          className={styles.logo}
        />
      </a>
    )
  }

  return (
    <div className={styles.header}>
      {logoElement()}
      <SocialIcons className={styles.socialIcons} />
      <div className={styles.linkListDesktop}>
        {renderMenuItems(headerItems)}
        {renderAuthState()}
        {/* TODO: Double check if this is where we want to land */}
        <button onClick={handlePurchaseClick} className={classnames(styles.getStartedButton, styles.button)}>Try Buildbox {defaultHomepage === "BBCLASSIC" ? "Classic" : 4}</button>
      </div>
      <div onClick={toggleMenu} className={styles.menuIconContainer}>
        <FontAwesomeIcon
          className={styles.menuIcon}
          icon={menuOpenState ? faTimes : faBars}
        />
      </div>
      {getMobileMenu()}
    </div>
  );
}
