import API from "@aws-amplify/api";

/**
 * Returns user email as md5 hash
 * @param {emailAddress}: string
 */

export const md5Hash = (emailAddress) => {
  var md5 = require("md5");
  return md5(emailAddress);
};

/**
 * Send an email containing download links for a mobile signup
 *
 * @param {accessToken}: string
 */

export const sendDownloadEmail = (accessToken, hashedEmail, productType) => {
  return new Promise((resolve, reject) => {
    API.post("userAccountManagementURL", "/mailchimp/emailers/download", {
      body: {
        app: productType,
        hashedEmail: hashedEmail,
      },
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response);
        }
      });
  });
};

const getMailchimpTag = (productType) => {
  const GENERIC_BB_TAGS = {
    name: "buildbox-generic",
    status: "active",
  };
  const BB2_TAGS = {
    name: "buildbox2-free",
    status: "active",
  };
  const BB3_TAGS = {
    name: "buildbox3-free",
    status: "active",
  };
  const BB4_TAGS = {
    name: "buildbox4-free",
    status: "active",
  };  
  const BBWORLD_TAGS = {
    name: "buildbox-world",
    status: "active",
  };
  // AP: TODO SOUNDBOX what mailchimp tags?
  const SOUNDBOX_TAGS = {
    name: "soundbox-free",
    status: "active",
  }

  switch (productType) {
    case "BB2":
      return BB2_TAGS;
    case "BB3":
      return BB3_TAGS;
    case "BB4":
      return BB4_TAGS;
    case "BBWorld":
      return BBWORLD_TAGS;
    case "generic":
      return GENERIC_BB_TAGS;
    case "BBBundle":
      return GENERIC_BB_TAGS;
    case "Soundbox":
      return SOUNDBOX_TAGS;
    default:
      return GENERIC_BB_TAGS;
  }
};
/**
 * Tags an subscriber with a buildbox2-free or buildbox3-free MC audience tag
 *
 * @param {hashedEmail}: MD5 Hash
 * @param {mailchimpTagName}: Mailchimp tag name
 * @param {isTagActive}: Mailchimp tag status
 */

export const addMailchimpAudienceTags = (emailAddress, productType) => {
  const email = emailAddress.toLowerCase();
  const hashedEmail = md5Hash(email);
  const { name, status } = getMailchimpTag(productType);

  return new Promise((resolve, reject) => {
    API.post("userAccountManagementURL", "/mailchimp/tags", {
      body: {
        isTagActive: status,
        mailchimpTagName: name,
        hashedEmail: hashedEmail,
      },
      headers: {
        "Content-Type": "application/json",
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response);
        }
      });
  });
};
