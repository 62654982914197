import Log from "../utils/Log";
import { v4 } from "uuid";
import actions from "../constants/actions.json";
import { DefaultThunkAction } from "./shared";
import { AnyAction } from "redux";
import PLANS_JSON from "../constants/planMetadata";
import { showError } from "./error";
import { setProductType } from "./current";
import { PlanIds } from "../reducers/models";

export function addPlanToCart(
  planId: PlanIds,
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    //TODO: Currently cart processes one item at a time - should be more dynamic as we add in more inventory/assets

    try {
      const subscriptionPlan: bb.model.ISubscription = PLANS_JSON[planId];

      if (subscriptionPlan.productType) {
        dispatch(setProductType(subscriptionPlan.productType))
      }
      else {
        throw new Error("Can't purchase right now, we lost track of the cart. Please try again.")
      }

      //maybe move this into analytics action?
      sessionStorage.setItem("planType", subscriptionPlan.planType);

      const planPurchaseObject = {
        cartId: v4(),
        item: subscriptionPlan,
        seatQuantity: 1,
      };

      await dispatch(addToCart([planPurchaseObject]));
    } catch (e:any) {
      dispatch(
        showError(
          "Sorry something went wrong while adding your item to cart. Please try again."
        )
      );
      Log.error(e, "error dispatching addPlanToCart:");
    }
  };
}

export function addToCart(items: bb.model.IPurchaseItem[]): AnyAction {
  return {
    type: actions.cart.ADD_TO_CART,
    items,
  };
}

export function clearCart(): AnyAction {
  return {
    type: actions.cart.CLEAR_CART,
  };
}

export function removeFromCart(cartId: string): AnyAction {
  return {
    type: actions.cart.REMOVE_FROM_CART,
    cartId,
  };
}

export function updateSeatQuantity(cartId: string, quantity: number) {
  return {
    type: actions.cart.UPDATE_SEAT_QUANTITY,
    cartId,
    quantity,
  };
}
