import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import { BBPlatformClient } from '../../../api/BBPlatform'
// import { handlePromptStepAnalytics } from '../../../api/GoogleAnalyticsWrapper'
import { customEventDataLayerTrigger } from '../../../api/GoogleTagManager'
import { bbaiStoryGamePromptOptions, bbaiStoryGamePromptStepStatus, bbaiUser } from '../constants/bbai.prompts'
// import ClaimLoginSkipOptions, { ClaimLoginSkipOptionsType } from './ClaimLoginSkipOptions'
import PromptMessage from './PromptMessage'
import currentUserInfo from "../../../selectors/currentUserInfo";
import currentStoryGame from "../../../selectors/currentStoryGame";
import { nextStage, setBBWorldBBDocId, setConfirmComplete, setErrorState } from '../../../actions/storyGame';
import { createCYOABBDoc, cyoaConfirmComplete } from '../../../api/storyGame';
import { validateToken } from '../../../actions/auth';

const BuildBBDocCYOA = () => {
  const bbaiStore = useSelector(currentStoryGame)
  const userStore = useSelector(currentUserInfo);
  const dispatch = useDispatch()
  ///TODO: make sure the the bbdoc is ready to download 
  const generateBBDoc = useRef<boolean>(false) // make sure the useFfect fetch call only runs once

  useEffect(() => {

    if(generateBBDoc.current === false && bbaiStore.allAssetsGenerated && !bbaiStore.completed){
      generateBBDOC();
      generateBBDoc.current = true;
    }

    if(bbaiStore.completed){
      dispatch(nextStage())
    }

    // if(generateBBDoc.current === false){
    //   generateBBDOC();
    //   generateBBDoc.current = true;
    // }
    // eslint-disable-next-line
  }, [] )


  // const handleClaimLoginSkip = (e: ClaimLoginSkipOptionsType) => {
  //   if (e === ClaimLoginSkipOptionsType.CLAIM) {
  //     console.debug(`[handleClaimLoginSkip] CLAIM`);
  //     handlePromptStepAnalytics(bbaiStoryGamePromptStepStatus.CLAIM_ACCOUNT);
  //     // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.CLAIM_ACCOUNT);
  //   } else if (e === ClaimLoginSkipOptionsType.LOGIN) {
  //     console.debug(`[handleClaimLoginSkip] LOGIN`);
  //     handlePromptStepAnalytics(bbaiStoryGamePromptStepStatus.LOGIN_ACCOUNT);
  //     // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.LOGIN_ACCOUNT);
  //   } else if(e === ClaimLoginSkipOptionsType.SKIP) {
  //     console.debug(`[handleClaimLoginSkip] DOWNLOAD aka SKIP`);
  //     handlePromptStepAnalytics(bbaiStoryGamePromptStepStatus.DOWNLOAD_BBDOC);
  //     // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.DOWNLOAD_BBDOC);
  //   }
  //   else {
  //     console.error("ERROR: unexpected state for claim/login/skip click")
  //   }
  // };

  const generateBBDOC = async (): Promise<void> => {
    try{
        console.log("CYOA ask platform to generate bbdoc...", "Game Data:", bbaiStore.generatedCYOAGameData )
        const refreshedToken = await dispatch(validateToken());

        const res = await createCYOABBDoc(refreshedToken, bbaiStore.projectId, bbaiStore.generatedCYOAGameData);

        customEventDataLayerTrigger("Web GenAI BBDoc Complete ", userStore.uuid)

        if (res.projectBBDocUrl === undefined) {
          console.error("BBDOC URL is undefined");
          throw new Error("BBDOC URL is undefined");
        }

        console.debug("generated bbdoc download link: ", res.projectBBDocUrl);
        console.log("generated bbdoc id: "+ res.bbdocId)
        dispatch(setBBWorldBBDocId(res.bbdocId));

        await cyoaConfirmComplete(refreshedToken, bbaiStore.projectId)
        customEventDataLayerTrigger("Web GenAI Complete", userStore.uuid)
        dispatch(setConfirmComplete())
        dispatch(nextStage())
        // setBBDoc(res.projectBBDocUrl);
    } catch(error){
      // TODO: handle error!!! I even added this console message, it was just empty here!
      console.error("Unexpected error while generating bbdoc: ", error)
      // throw error
      dispatch(setErrorState({
        title: "Error generating Story Game download",
        message: bbaiStoryGamePromptOptions[bbaiStore.promptStepStatus]?.errPrompt,
      }))
      // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.ERROR_RESTART_REGENERATE)
    }
  }

  
  return (
    <>
      {<PromptMessage promptStage={bbaiStoryGamePromptStepStatus.BUILD_BBDOC} message={bbaiStoryGamePromptOptions.BUILD_BBDOC.prompt} user={bbaiUser}
        completed={bbaiStore.completed}
      /> }
      {/* { bbaiStore.isGuest && <ClaimLoginSkipOptions handleClick={handleClaimLoginSkip}/>} */}
    </>
  )
}

export default BuildBBDocCYOA