import React from "react";
import BuildBBDocCYOA from "../components/BuildBBDocCYOA";
import { DownloadBBDocCYOA } from "../components/DownloadBBDocCYOA";
import { GeneratingGameAssetsCYOA } from "../components/GeneratingGameAssetsCYOA";
import GeneratingGameDataCYOA from "../components/GeneratingGameDataCYOA";
import InitialCYOAPrompt from "../components/InitialCYOAPrompt";
import { Display } from "./bbai.prompts";

export const mapPromptToDisplay: Record<string, Display> = {
  "INITIAL": {
    Component: <InitialCYOAPrompt/>,
    stage: 0,
    nextStage: "GENERATING_GAME_DATA"
  },
  "GENERATING_GAME_DATA": {
    Component: <GeneratingGameDataCYOA/>,
    stage: 1,
    nextStage: "GENERATING_GAME_ASSETS"
  },
  "GENERATING_GAME_ASSETS": {
    Component: <GeneratingGameAssetsCYOA/>,
    stage: 2,
    nextStage: "BUILD_BBDOC"
  },
  "BUILD_BBDOC": {
    Component: <BuildBBDocCYOA/>,
    stage: 3,
    nextStage: "DOWNLOAD_BBDOC"
  }, 
  "DOWNLOAD_BBDOC": {
    Component: <DownloadBBDocCYOA/>,
    stage: 4,
    nextStage: ""
  }
};