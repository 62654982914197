export function validateLength (str: string): boolean {
    if (str.length < 3) {
      return false
    }
    return true
}

export const validateEmail = (email:string):boolean => {
  const  valid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (!valid) {
    return false
  }
  return true
};

// more validations 