import { AnyAction } from "redux";
import actions from "../constants/actions.json";
import { bbaiStoryGamePromptStepStatus, BBDocResponseObj } from "../constants/GenPrompTypes";
import { BBAI_STORE_VERSION, storeStorageVersion } from "../utils/StorageVersion";
import { v4 as uuidv4 } from "uuid";
import { mapPromptToDisplay } from "../containers/genai/constants/mapPromptToDisplay";

export const CYOAProjectType = "cyoa";

const BBAI_STORAGE_KEY = "bbaiCyoa-Store";


storeStorageVersion(BBAI_STORE_VERSION, BBAI_STORAGE_KEY);

// localStorage.removeItem(BBAI_STORAGE_KEY);

export interface errorBody {
  title: string;
  message: string;
}

export interface IStoryGameState {
  projectType: string;
  version: number;
  isLoading: boolean; 
  completed?: boolean; 
  error?: errorBody; 
  promptStepStatus: bbaiStoryGamePromptStepStatus;
  userStoryPrompt: string;
  generatedCYOAGameData: any | null;
  projectId: string;
  allAssetsGenerated: boolean;
  bbworldBBDocId: string; 
  isGuest: boolean;
  bbDocHistory: BBDocResponseObj[]|null
  // promptComponents: Display[] //React.ReactNode[]; //
  showAuthFrame: boolean;
  isAuthed: boolean;
  createAccountMode: boolean;
  userId: string | null;
  bbDocUrl: string | null;
  gameDataRestartID: string | null;
  titleImage: File | null;
}

export const initialStoryGameState: IStoryGameState = {
  projectType: CYOAProjectType,
  version: BBAI_STORE_VERSION,
  isLoading: false,
  error: undefined,
  promptStepStatus: bbaiStoryGamePromptStepStatus.INITIAL,
  userStoryPrompt: "",
  generatedCYOAGameData: null,
  allAssetsGenerated: false,
  projectId: uuidv4(), // doesn't seeem to generate correctly
  bbworldBBDocId: "",
  completed: false,
  isGuest: false,
  bbDocHistory: null,
  // promptComponents: [mapPromptToDisplay["INITIAL"]],
  showAuthFrame: false,
  isAuthed: false,
  createAccountMode: false,
  userId: null,
  bbDocUrl: null,
  gameDataRestartID: null,
  titleImage: null
}; 


const storyGameReducer = (
  state: IStoryGameState = initialStoryGameState,
  action: AnyAction
):IStoryGameState => {
   switch (action.type) {
    case actions.storyGame.SET_LOADING: 
      return {
        ...state,
        isLoading: action.loading
      }
    case actions.storyGame.INITIAL:
      return {
        ...initialStoryGameState
      }
    case actions.storyGame.SET_ERROR:
      return {
        ...state,
        error: action.error
      }
    case actions.storyGame.SET_PROMPT_STEP:
      return {
        ...state,
        promptStepStatus: action.stepStatus
      }
    case actions.storyGame.SET_USER_STORY_PROMPT:
      return {
        ...state,
        userStoryPrompt: action.prompt
      }
    case actions.storyGame.SET_GENERATED_STORY_GAME_DATA:
      return {
        ...state,
        generatedCYOAGameData: action.data
      }
    case actions.storyGame.SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.id
      }
    case actions.storyGame.SET_ALL_ASSETS_GENERATED:
      return {
        ...state,
        allAssetsGenerated: action.allAssetsGenerated
      }
    case actions.storyGame.SET_BBWORLD_BBDOC_ID:
      return {
        ...state,
        bbworldBBDocId: action.id
      }
    case actions.storyGame.SET_BBDOC_HISTORY:
      return {
        ...state,
        bbDocHistory: action.history
      }
    case actions.storyGame.SET_GUEST: 
      return { 
        ...state,
        isGuest: action.isGuest
      }
    case actions.storyGame.NEXT_STAGE:
      const currentStage = state.promptStepStatus
      const nextStage = mapPromptToDisplay[bbaiStoryGamePromptStepStatus[currentStage]]?.nextStage
      return {
        ...state,
        promptStepStatus: bbaiStoryGamePromptStepStatus[nextStage as bbaiStoryGamePromptStepStatus],
      }
    case actions.storyGame.SET_STAGE: 
      return {
        ...state,
        promptStepStatus: action.stage
      }
    case actions.storyGame.SET_SHOW_AUTH_FRAME:
      return {
        ...state,
        showAuthFrame: action.showAuthFrame
      }
    case actions.storyGame.SET_IS_AUTH: 
      return {
        ...state,
        isAuthed: action.isAuthed
      }
    case actions.storyGame.SET_CREATE_ACCOUNT_MODE:
      return {
        ...state,
        createAccountMode: action.createAccountMode
      }
    case actions.storyGame.SET_USER_ID: 
      return {
        ...state,
        userId: action.userId
      }
    case actions.storyGame.SET_BBDOC_URL:
      return {
        ...state,
        bbDocUrl: action.url
      }
    case actions.storyGame.START_OVER:
      return {
        ...initialStoryGameState,
        isAuthed: state.isAuthed,
      }
    case actions.storyGame.SET_RETRY_ID:
      return {
        ...state,
        gameDataRestartID: action.id
      }
    case actions.storyGame.SET_CONFIRM_COMPLETE:
      return {
        ...state,
        completed: true,
      }
    case actions.storyGame.GET_COMPONENTS_ARRAY: 
      return {
        ...state,
      }
    case actions.storyGame.SET_TITLE_IMAGE:
      return {
        ...state,
        titleImage: action.titleImage
      }
    default:
      return state;
  }
}



export default storyGameReducer;

