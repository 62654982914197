import API from "@aws-amplify/api";
import Log from "../utils/Log";

export const createUserRegistrationRequest = (
  email,
  clientId,
  userId,
  productType,
  deviceType
) => {
  return new Promise((resolve, reject) => {
    API.post("userAccountManagementURL", "/analytics/register", {
      body: {
        customerId: userId,
        clientId: clientId,
        email: email,
        app: productType,
        platform: deviceType,
      },
      headers: {
        "Content-Type": "application/json",
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(
            res.data,
            "SUCCESS - createUserRegistrationRequest response:"
          );
          resolve(res.data);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in createUserRegistrationRequest");
          reject(error);
        }
      });
  });
};

export const createUserPurchaseRequest = (
  email,
  clientId,
  userId,
  planType,
  planInterval,
  product
) => {
  return new Promise((resolve, reject) => {
    API.post("userAccountManagementURL", "/analytics/purchase", {
      body: {
        customerId: userId || email,
        clientId: clientId,
        email: email,
        plan: planType,
        planInterval: planInterval,
        app: product,
      },
      headers: {
        "Content-Type": "application/json",
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(res.data, "SUCCESS - createUserPurchaseRequest response:");
          resolve(res.data);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in createUserPurchaseRequest");
          reject(error);
        }
      });
  });
};

export const createUserDownloadsRequest = async (clientId, version) => {
  let error;
  for (let ii = 0; ii < 5; ii++) {
    try {
      let resp = await sendUserDownloadRequestAnalytics(clientId, version);
      return resp;
    } catch (err) {
      console.log("cudr error: ", err);
      error = err;
    }
  }

  console.log("throw cudr error: ", error);
  throw error;
};

export const sendUserDownloadRequestAnalytics = (clientId, version) => {
  return new Promise((resolve, reject) => {
    API.post("userAccountManagementURL", "/analytics/download", {
      body: {
        clientId: clientId,
        app: version,
      },
      headers: {
        "Content-Type": "application/json",
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(
            error,
            "error caught in createUserDownloadsRequest: ",
            error
          );
          console.log("errrrrrorrrrr ", error);
          reject(error);
        }
      });
  });
};
