import { AnyAction } from "redux";
import actions from "../constants/actions.json";

const initialState: bb.state.ICart = {
  quantity: 0,
  items: [],
};
const cartReducer = (
  state: bb.state.ICart = initialState,
  action: AnyAction
): bb.state.ICart => {
  switch (action.type) {
    case actions.cart.ADD_TO_CART:
      return {
        quantity: action.items.length,
        items: action.items,
      };
    case actions.cart.REMOVE_FROM_CART:
      const filteredCart = state.items.filter(
        (item) => item.cartId !== action.cartId
      );
      return {
        ...state,
        items: filteredCart,
        quantity: filteredCart.length,
      };
    case actions.cart.UPDATE_SEAT_QUANTITY:
      let plan = state.items.find((item) => item.cartId === action.cartId);
      let updatedCart = state.items.filter(
        (item) => item.cartId !== action.cartId
      );

      if (plan) {
        let seats = action.quantity;
        if (seats < 1 || seats === "") {
          seats = 1;
        }
        plan.seatQuantity = seats;
        updatedCart.push(plan);
      }

      return {
        ...state,
        items: updatedCart,
      };
    case actions.cart.CLEAR_CART:
      return initialState;
    default:
      return state;
  }
};

export default cartReducer;
