import React from 'react'
import { useSelector } from 'react-redux';
import currentStoryGame from '../../../selectors/currentStoryGame';
import styles from '../../../styles/PreviewBit.module.scss'

const PreviewBit = () => {
  const bbaiStore = useSelector(currentStoryGame);

  const css = {
    backgroundImage: `url("data:image/gif;base64,${bbaiStore.titleImage}")`, // index change
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  return (
    <>
      <div className={styles.container} style={bbaiStore.titleImage ? css : {}}>
        <div className={styles.previewBitContainer}>
          <div>
            <div className={styles.bgInfoBase}>
              <p className={styles.textInfoTitle} >{bbaiStore.generatedCYOAGameData.title.title}</p>
            </div>
            <div className={styles.bgInfoBase}>
              <p className={styles.textInfoMission}>{bbaiStore.generatedCYOAGameData.title.mission}</p>
            </div>
          </div>
          <div className={styles.startImgContainer}>
            <img src={"https://frontend-assets.buildbox.com/Start.png"} alt="start" className={styles.startImg}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreviewBit
