import { AnyAction } from "redux";
import { HWAccountStatus, HWVerificationStatus, WorkState } from "../reducers/models";
import actions from "../constants/actions.json";
import { filterReducerAdboxReportItem } from "../utils/reducerHelperUtils";

const initialState: bb.state.IMonetization = {
  workState: {
    status: HWAccountStatus.UNREGISTERED,
    verificationStatus: HWVerificationStatus.REQUIRED,
  },
  accountInfo: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    country: "",
    email: "",
  },
  paymentHistory: {
    transactions: [],
  },
  projects: {
    games: [],
  },
  adboxReport: {
    workState: WorkState.None,
    networkReports: undefined 
  },
  monthToDateEarnings: {
    workState: WorkState.None,
    earnings: NaN,
  },
  filters: {
    networks: {
      appLovin: true,
      vungle: true
    },
    word: "",
    os: { ios: true, 
      android: true},
      networkReports: [] 
  },
  adboxDailyMetrics: {
    workState: WorkState.None,
    dailyMetrics: undefined,
  },
};
const monetizationReducer = (
  state: bb.state.IMonetization = initialState,
  action: AnyAction
): bb.state.IMonetization => {
  switch (action.type) {
    case actions.monetization.SET_HW_ACCOUNT_STATE:
      return {
        ...state,
        workState: action.accountState,
      };
    case actions.monetization.SET_HW_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: action.accountInfo,
      };
    case actions.monetization.SET_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          transactions: [...action.transactions],
        },
      };
    case actions.monetization.SET_PROJECTS:
      return {
        ...state,
        projects: {
          ...state.projects,
          games: [...action.projects],
        },
      };
     case actions.monetization.SET_MONTH_TO_DATE_EARNINGS:
      return{
        ...state,
        monthToDateEarnings: action.monthToDateEarnings
      }
    case actions.account.CLEAR_ACCOUNT:
      return initialState;
    case actions.monetization.SET_ADBOX_REPORT:
      return {
        ...state,
        adboxReport: action.adboxReport,
        filters: {
          ...state.filters,
          networkReports: filterReducerAdboxReportItem(action.adboxReport.networkReports, state.filters.os, state.filters.networks, state.filters.word),
        }
      }
    case actions.monetization.SET_NETWORK_FILTER:
      return {
        ...state,
        filters:{
          ...state.filters,
          networks: action.filters,
          networkReports: filterReducerAdboxReportItem(state.adboxReport.networkReports, state.filters.os, action.filters, state.filters.word),
        }
      }
    case actions.monetization.SET_BUNDLE_FILTER_WORD:
      return {
      ...state,
       filters: {
         ...state.filters,
         word: action.bundleFilterWord,
         networkReports: filterReducerAdboxReportItem(state.adboxReport.networkReports, state.filters.os, state.filters.networks, action.bundleFilterWord),
       }  
    }
    case actions.monetization.SET_PLATFORM_OS_FILTER:
      return {
        ...state,
        filters:{
          ...state.filters,
          os: action.filters,
          networkReports:  filterReducerAdboxReportItem(state.adboxReport.networkReports, action.filters, state.filters.networks, state.filters.word),
        }
      }
    case actions.monetization.SET_FILTERED_TABLE:{ 
      return {
        ...state,
        filters:{
          ...state.filters,
          networkReports:  filterReducerAdboxReportItem(state.adboxReport.networkReports, state.filters.os, state.filters.networks, state.filters.word),
        }
      }
    }
    case actions.monetization.SET_DAILY_METRICS:
      return {
        ...state,
        adboxDailyMetrics: action.adboxDailyMetrics,
      }
    default:
      return state;
  }
};

export default monetizationReducer;
