import React, { useEffect, useRef, useState } from 'react'
import { bbaiStoryGamePromptOptions, bbaiStoryGamePromptStepStatus, bbaiUser } from '../constants/bbai.prompts'
import currentStoryGame from "../../../selectors/currentStoryGame";
import PromptMessage from './PromptMessage'
import { v4 as uuidv4 } from "uuid";
import currentUserInfo from "../../../selectors/currentUserInfo";
import { customEventDataLayerTrigger } from '../../../api/GoogleTagManager'
import { useDispatch, useSelector } from 'react-redux';
// import { BBPlatformClient } from '../../../api/BBPlatform';
import { nextStage, setAllAssetsGenerated, setErrorState, setGeneratedCYOAGameData, setProjectId, setTitleImage } from '../../../actions/storyGame';
import { validateToken } from '../../../actions/auth';
import { cyoaNewId, emplaceCYOAResource, generateClipdropAsset, getBalance } from '../../../api/storyGame';

export const GeneratingGameAssetsCYOA = () => {

  const bbaiStore = useSelector(currentStoryGame)
  const userStore = useSelector(currentUserInfo);
  const dispatch = useDispatch()
  // const userStore = useSelector(currentUserInfo);
  const genAssetsRan = useRef(false) // make sure the useFfect fetch call only 
  const startTimer = useRef(false)
  const [generatedDataSuccess, setGeneratedDataSucesss] = useState<boolean>(false)

  useEffect(() => {
    if (bbaiStore.generatedCYOAGameData === null) {
      return;
    }
    if(!genAssetsRan.current && !bbaiStore.completed) {
      startTimer.current = true
      generateCYOAGameAssets();

    }

    if(bbaiStore.completed){
      dispatch(nextStage())
    }
    return () => {
      // allow the items to run once here
      genAssetsRan.current = true
    }
    // eslint-disable-next-line
  }, [ bbaiStore.generatedCYOAGameData ]);  

  const generateCYOAGameAssets = async() => {
    // TODO: remove debug
    let skipGenerate = false
    try {
      const refreshedToken = await dispatch(validateToken());
      const userCreditsInfo = await getBalance(refreshedToken)

      if (userCreditsInfo.balance < 1) {
        skipGenerate = true;

        // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.ERROR_RESTART_REGENERATE)
      }

    if (bbaiStore.generatedCYOAGameData === null) { 
      console.error("ERROR: no game data to generate assets from")
    }

    if(skipGenerate) {
      console.log("skipping generating CYOA game assets")
    }
    else {
      console.log("generating CYOA game assets")
      const refreshedToken = await dispatch(validateToken());

      const newProjectID = await cyoaNewId(refreshedToken)
      dispatch(setProjectId(newProjectID))
      //TODO: remove the project id from the known state.

      // things to do...
      let jobRequest = {
        prompt: "",
        costInVC: 0,
        disableSuggestedOptimizations: true,
        jobId: uuidv4(),
        width: 512,
        height: 896,
        removeBackground: false,
        seed: Math.floor(Math.random() * 1000000),
        webCYOASaveCopyToS3Filename: "",
        webCYOAProjectId: newProjectID,
        projectId: newProjectID,
      }

      let promisesImages:any[] = []

      console.log("generating CYOA game assets", bbaiStore.generatedCYOAGameData)
      // create title screen image
      jobRequest.jobId = uuidv4()
      jobRequest.webCYOASaveCopyToS3Filename = "title.png"    
      jobRequest.prompt = bbaiStore.generatedCYOAGameData.title.imageDescription
      promisesImages.push(generateClipdropAsset(refreshedToken, jobRequest))

      // create win screen image
      jobRequest.jobId = uuidv4()
      jobRequest.webCYOASaveCopyToS3Filename = "win.png"    
      jobRequest.prompt = bbaiStore.generatedCYOAGameData.win.imageDescription
      promisesImages.push(generateClipdropAsset(refreshedToken, jobRequest))

      // create lose screen image
      jobRequest.jobId = uuidv4()
      jobRequest.webCYOASaveCopyToS3Filename = "lose.png"    
      jobRequest.prompt = bbaiStore.generatedCYOAGameData.lose.imageDescription
      promisesImages.push(generateClipdropAsset(refreshedToken, jobRequest))

      // create all chapter images
      let chapterInfo: any[] = bbaiStore.generatedCYOAGameData.chapters
      for(let ii = 0; ii < chapterInfo.length; ii++) {
        const chapter : any = chapterInfo[ii]
        jobRequest.jobId = uuidv4()
        jobRequest.webCYOASaveCopyToS3Filename = `chapter${ii}.png`    
        jobRequest.prompt = chapter.imageDescription
        promisesImages.push(generateClipdropAsset(refreshedToken, jobRequest))
      }

      // create icon? NYFI
      jobRequest.jobId = uuidv4()
      jobRequest.webCYOASaveCopyToS3Filename = "icon.png"
      jobRequest.width = 512
      jobRequest.height = 512
      jobRequest.prompt = "Make an app icon for a game: " + bbaiStore.generatedCYOAGameData.title.title + " " + bbaiStore.generatedCYOAGameData.title.mission //bbaiStore.generatedCYOAGameData.iconImageDescription
      promisesImages.push(generateClipdropAsset(refreshedToken, jobRequest))   

      // TODO: get music?
      // for now let's hardcode it since I'm missing a prompt to send to our system to get a url
      // until then, we'll just put a url manually
      let musicUrl = "https://frontend-assets.dev.8cell.com/bg.mp3"
      await emplaceCYOAResource( refreshedToken, newProjectID, "background_music.mp3", musicUrl)

      const values = await Promise.all(promisesImages)
    
      customEventDataLayerTrigger("Web GenAI Images Complete", userStore.uuid)

      await emplaceCYOAResource(refreshedToken, newProjectID, "background_music.mp3", musicUrl)
      customEventDataLayerTrigger("Web GenAI Sound Complete", userStore.uuid)


      // console.debug("asset generation complete!", values)
      // await BBPlatformClient.cyoaConfirmComplete(newProjectID)

      dispatch(setTitleImage(values[0].image))
      
      setGeneratedDataSucesss(true)
      dispatch(setAllAssetsGenerated(true))
      // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.BUILD_BBDOC);
      
      dispatch(nextStage())
    }
    } catch (err) {
      console.error("ERROR: while generating game assets: ", err)
      skipGenerate = true
        dispatch(setErrorState({
          title: "Error generating Story Game assets",
          message: bbaiStoryGamePromptOptions[bbaiStore.promptStepStatus]?.errPrompt,
        }));     
        dispatch(setGeneratedCYOAGameData(null))
        // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.ERROR_RESTART_REGENERATE)
    }
  }
   return (
    <>
      <PromptMessage promptStage={bbaiStoryGamePromptStepStatus.GENERATING_GAME_ASSETS} message={bbaiStoryGamePromptOptions.GENERATING_GAME_ASSETS.prompt} user={bbaiUser}
      loading={startTimer.current} successReport={generatedDataSuccess}
      error={bbaiStore.error !== undefined}
      completed={bbaiStore.completed}
      /> 
    </>
  )
}
