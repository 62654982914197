import actions from "../constants/actions.json";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { DefaultThunkAction } from "./shared";

//for global toast errors
export function dispatchError(
  error: string
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    setTimeout(() => {
      toast.error(error, {
        toastId: v4(),
        onClose: () => dispatch(clearError()),
      });
    }, 100);

    await dispatch(clearError());
  };
}

export function clearError() {
  toast.dismiss();
  return {
    type: actions.error.HIDE_ERROR,
  };
}
//for global errors
export function showError(error: string) {
  return {
    type: actions.error.SHOW_ERROR,
    error,
  };
}
