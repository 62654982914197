// import { showError } from "./error";
import { AnyAction } from "redux";
// import { PlanType, WorkState } from "../reducers/models";
import actions from "../constants/actions.json";
// import { getHouseAdCampaign, getSaleData } from "../utils/saleUtils";
import { errorBody } from "../reducers/storyGameReducer";
import { bbaiStoryGamePromptStepStatus, BBDocResponseObj } from "../constants/GenPrompTypes";
import { Display } from "../containers/genai/constants/bbai.prompts";
import { mapPromptToDisplay } from "../containers/genai/constants/mapPromptToDisplay";
// import { getDiscountedPlanPricing } from "../utils/saleUtils";

// export function resetSaleState(): DefaultThunkAction<Promise<void>> {
//   return async (dispatch) => {
//     Log.trace("", "clearing sale state");
//     sessionStorage.removeItem("promoCode");
//     window.history.replaceState(null, window.location.pathname);

//     dispatch(clearSaleState());
//   };
// }

export function loadingState(loading: boolean): AnyAction {
  return {
    type: actions.storyGame.SET_LOADING,
    loading,
  };
}

export function setPolling(polling: boolean): AnyAction {
  return {
    type: actions.storyGame.SET_POLLING,
    polling,
  };
}

export function initial(): AnyAction {
  return {
    type: actions.storyGame.INITIAL,
    // showSaleUI,
  };
}

export function setErrorState(error: errorBody | undefined): AnyAction {
  return {
    type: actions.storyGame.SET_ERROR,
    error,
  };
}

export function setPromptStepState(stepStatus: bbaiStoryGamePromptStepStatus): AnyAction {
  return {
    type: actions.storyGame.SET_PROMPT_STEP,
    stepStatus,
  };
}

export function setUserStoryPrompt(prompt: string): AnyAction {
  return {
    type: actions.storyGame.SET_USER_STORY_PROMPT,
    prompt,
  };
}

export function setGeneratedCYOAGameData(data: object | null): AnyAction {
  return {
    type: actions.storyGame.SET_GENERATED_STORY_GAME_DATA,
    data,
  };
}

export function setProjectId(id: string): AnyAction {
  return {
    type: actions.storyGame.SET_PROJECT_ID,
    id
  };
}

export function setAllAssetsGenerated(allAssetsGenerated: boolean): AnyAction {
  return {
    type: actions.storyGame.SET_ALL_ASSETS_GENERATED,
    allAssetsGenerated
  };
}

export function setBBWorldBBDocId(id:string ): AnyAction {
  return {
    type: actions.storyGame.SET_BBWORLD_BBDOC_ID,
    id
  }
}

export function setBBDocHistory(history:BBDocResponseObj[]|null): AnyAction {
  return {
    type: actions.storyGame.SET_BBDOC_HISTORY,
    history
  }
}

export function setGuest(isGuest: boolean): AnyAction {
  return {
    type: actions.storyGame.SET_GUEST,
    isGuest
  }
}

export function nextStage(): AnyAction {
  return {
    type: actions.storyGame.NEXT_STAGE,
  }
}

export function setStage(stage: bbaiStoryGamePromptStepStatus): AnyAction {
  return {
    type: actions.storyGame.SET_STAGE,
    stage
  }
}

export function setShowAuthFrame(showAuthFrame: boolean): AnyAction {
  return {
    type: actions.storyGame.SET_SHOW_AUTH_FRAME,
    showAuthFrame
  }
}

export function setIsAuthed(isAuthed: boolean): AnyAction {
  return {
    type: actions.storyGame.SET_IS_AUTH,
    isAuthed
  }
}

export function setCreateAccountMode(createAccountMode: boolean): AnyAction {
  return {
    type: actions.storyGame.SET_CREATE_ACCOUNT_MODE,
    createAccountMode
  }
}

export function setUserId(userId: string): AnyAction {
  return {
    type: actions.storyGame.SET_USER_ID,
    userId
  }
}

export function setBBDocUrl(url: string): AnyAction {
  return {
    type: actions.storyGame.SET_BBDOC_URL,
    url
  }
}

export function startOver(): AnyAction {
  return {
    type: actions.storyGame.START_OVER,
  }
}

export function setRetryID(id: string | null): AnyAction {

  //  console.log("setRestartID", id);
  let newComponentArray:Display[] = []

  for (const [key, value] of Object.entries(mapPromptToDisplay)) {
    newComponentArray.push(value)
    if(key === bbaiStoryGamePromptStepStatus.INITIAL) {
      break;
    }    
  }
          
  return {
    type: actions.storyGame.SET_RETRY_ID,
    id
  }
}

export function getComponentsArray(id: string | null): AnyAction {
  return {
    type: actions.storyGame.GET_COMPONENTS_ARRAY,
    id
  }
}

export function setConfirmComplete(): AnyAction {
  return {
    type: actions.storyGame.SET_CONFIRM_COMPLETE,
  }
}

export function setTitleImage(titleImage: File | null): AnyAction {
  return {
    type: actions.storyGame.SET_TITLE_IMAGE,
    titleImage
  }
}