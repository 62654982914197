import React, { useEffect, Suspense } from "react";
import { Route } from "react-router-dom";

export default function AppliedRoute({ component: C, appProps, ...rest }) {
  useEffect(() => {
    //to bring to top of page since react-router remembers your scroll pos?
    window.scrollTo(0, 0);

    //TODO: this may need to include title ie below, pass informatin through the props
    // window.dataLayer.push({
    //   event: "pageview",
    //   page: {
    //     url: location,
    //     title: title,
    //   },
    // });
    window.dataLayer.push({
      event: "pageview",
    });
  });
  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<div style={{ height: "100vh" }}>Loading...</div>}>
          <C {...props} {...appProps} />
        </Suspense>
      )}
    />
  );
}
