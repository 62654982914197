import {useEffect, useState} from 'react'

type Props = {
  successReport: boolean;
}
const useInfiniteProgress = (props: Props) => {

  const { successReport } = props;
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    let interval: any;
    interval = setInterval(() => {
      setProgress((prev) => {
        if (successReport || prev >= 100) {
          clearInterval(interval);
          return 100
        }else if (prev > 90){

          return 98
        }else if(!prev) {
          // clearInterval(interval);
          return randomeNumberIncrement();
        } else if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + randomeNumberIncrement();
      });
    }, 3000); // increment every 3 seconds

    return () => {
      clearInterval(interval);
    }

  }, [successReport ]);

  const randomeNumberIncrement = ():number => {
    // number between 1 and 5, don't wanna return zero just in case
    return Math.floor(Math.random() * 5) + 1;
  }

  return progress
}

export default useInfiniteProgress