// take in reducer values
// take in the values that mateter
// intake params for the old arry, new filter settings,
// return the filter arry for the valu

//reduce the normal state athat fits the reudcer conditions
// need to break it up

type osPlatformFilter = bb.model.monetization.osPlatformFilter;
type INetworkReport = bb.model.monetization.INetworkReport;
type INetworkFilter = bb.model.monetization.INetworkFilter;
type IAdBoxNetworkReportItem = bb.model.monetization.IAdBoxNetworkReportItem;

// TODO: refactor this later?!
// export const networkArrayfilterReducer = (networks:INetworkReport[] | undefined, platformFilter:osPlatformFilter, word:string ):INetworkReport[] => {

//   if (undefined === networks)
//     return [];
    
//   const filterNetworkReports:INetworkReport[] = [];

//   // TODO:  filter neworks, 

//   networks.forEach((network) => {
//     let reducedData = filterReducerAdboxReportItem(network.reports, platformFilter, word )
//     filterNetworkReports.push({
//       networkName: network.networkName,
//       reports: reducedData
//     })
//   })

//   return filterNetworkReports;
// }

export const combineNetworks = (networkReports: INetworkReport[] ): IAdBoxNetworkReportItem[] => {

  if (undefined === networkReports ||  networkReports.length === 0)
    return [];

  return networkReports
    .map(({networkName, reports}) => reports.map(item => ({networkName, ...item})))
    .flat()
  // let combinedNetwork:IAdBoxNetworkReportItem[] = [];

  // networkReports.forEach((network: INetworkReport) => {
  //   const networkName = network.networkName;

  //   network.reports?.map((report: IAdboxReportItem) => {
  //     const addedNetworkName = (report.results as any[]).map((result: IAppLovinReportItem | IVungleReportItem) => {
  //       return {
  //         ...result,
  //         network: networkName,
  //       }
  //     })
  //     combinedNetwork = [...combinedNetwork, ...addedNetworkName as any]; 
  //   });
  // })

  // return combinedNetwork;
}

export const filterReducerAdboxReportItem = (reportItems:IAdBoxNetworkReportItem[] | undefined, platformFilter:osPlatformFilter, networkFilter: INetworkFilter ,word:string ):IAdBoxNetworkReportItem[] => {

  if (undefined === reportItems)
    return [];
    
  const newReportItems:IAdBoxNetworkReportItem[] = (reportItems as any[]).filter((reportItem:IAdBoxNetworkReportItem)=> {

    if( reportItem.platform ===  "ios" && platformFilter.ios){
      return true;
    }
    if( reportItem.platform === "android" && platformFilter.android) {
      return true
    }

    return false;
  });

  const reducedNetworks:IAdBoxNetworkReportItem[] = (newReportItems as any[]).filter((reportItem:IAdBoxNetworkReportItem)=> {

    if( (reportItem.networkName ===  "applovin" && networkFilter.appLovin) || (reportItem.networkName === 'vungle' && networkFilter.vungle)){
      return true;
    }

    return false;
  });

  const finalReduced:IAdBoxNetworkReportItem[] = (reducedNetworks as any[]).filter((reportItem:IAdBoxNetworkReportItem) => {
    
    const bundleID = reportItem.package_name.split(".");

    if( bundleID[0].includes(word)) return true;
    if( bundleID[1] !== undefined && bundleID[1].includes(word)) return true;
    if( bundleID[2] !== undefined && bundleID[2].includes(word)) return true;
    return false;
  })

  return finalReduced;
}
