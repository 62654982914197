import { AnyAction } from "redux";
import actions from "../constants/actions.json";

const initialState: bb.state.IError = {
  error: null,
};

const errorReducer = (
  state: bb.state.IError = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actions.error.SHOW_ERROR:
      return {
        error: action.error,
      };
    case actions.error.HIDE_ERROR:
      return {
        error: null,
      };
    default:
      return state;
  }
};

export default errorReducer;
