import React, { useState, useEffect } from "react";
import styles from "../styles/Header.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import classnames from "classnames";

export const MenuItem = (props) => {
  const [menuOpenState, toggleMenuState] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isDesktop = windowWidth >= 650;
  const { menuItem } = props;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function toggleTab(path) {
    if (props.activeTab) {
      return props.activeTab === path ? styles.activeTab : "";
    }
  }

  const toggleMenu = (event) => {
    event.stopPropagation();
    toggleMenuState(!menuOpenState);
  };

  const renderSubMenuItems = (submenus) => {
    return (
      <div
        className={
          menuOpenState
            ? styles.dropDownContent
            : classnames(styles.dropDownContent, styles.hidden)
        }
      >
        {submenus.map((submenu, i) => {
          return submenu.link !== "" ? (
            <Link to={submenu.link} key={submenu.title}>
              {submenu.title}
            </Link>
          ) : (
            <a
              key={submenu.key}
              href={submenu.externalURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {submenu.title}
            </a>
          );
        })}
      </div>
    );
  };

  const { title } = menuItem;

  const renderParentMenu = () => {
    if (menuItem.route.length) {
      return (
        <Link to={menuItem.route} key={title}>
          <p className={toggleTab(menuItem.route)}>{title}</p>
        </Link>
      );
    } else if (menuItem.externalURL.length) {
      return (
        <a
          href={menuItem.externalURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>{title}</p>
        </a>
      );
    } else {
      return (
        <p onClick={toggleMenu} className={styles.parentMenuTitle}>
          {title}
        </p>
      );
    }
  };

  return (
    <div key={title} className={styles.dropDown}>
      <div className={styles.menuTitleContainer}>
        {renderParentMenu()}

        {menuItem.subMenu && (
          <FontAwesomeIcon
            className={styles.menuIcon}
            icon={menuOpenState && !isDesktop ? faAngleUp : faAngleDown}
            onClick={toggleMenu}
          />
        )}
      </div>

      {menuItem.subMenu && renderSubMenuItems(menuItem.subMenu)}
    </div>
  );
};
