import React, { useState, useEffect } from "react";
import styles from "../styles/GenericLogoLander.module.scss";
import { isUnsupportedBrowser } from "../utils/browserUtils";

export const RoadblockUI = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img
          src={"https://frontend-assets.buildbox.com/Buildbox__Icon_Only.png"}
          className={styles.BBLogo}
          alt="Buildbox Logo"
        />
        <h3>
          Your browser is deprecated. Please use the latest version of Chrome,
          Firefox, Microsoft Edge, or Safari for an optimal experience.
        </h3>
      </div>
    </div>
  );
};

export default function BrowserRoadblock({ children }) {
  const [isDeprecated, setRoadblock] = useState(false);

  const detectBrowser = () => {
    const isIE = isUnsupportedBrowser();

    if (isIE) {
      setRoadblock(true);
      sessionStorage.setItem("isDeprecated", true);
    } else {
      setRoadblock(false);
      sessionStorage.setItem("isDeprecated", false);
    }
  };

  useEffect(() => {
    detectBrowser();
  }, [isDeprecated]);

  return <>{isDeprecated ? <RoadblockUI /> : children}</>;
}
