import { AnyAction } from "redux";
import actions from "../constants/actions.json";

const initialState: bb.state.IAuth = {
  user: null,
  gaClientId: null,
  fromBBClient: false,
  cognitoClientId: null,
  clientSecret: null,
  authTokens: null,
  screenName: null,
};

const authReducer = (
  state: bb.state.IAuth = initialState,
  action: AnyAction
): bb.state.IAuth => {
  switch (action.type) {
    case actions.auth.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actions.auth.SET_SCREEN_NAME: 
      return {
        ...state,
        screenName: action.screenName
      };
    case actions.auth.SET_GA_CLIENT_ID:
      return {
        ...state,
        gaClientId: action.gaClientId,
      };
    case actions.auth.SET_AUTH_TOKENS:
      return {
        ...state,
        authTokens: action.tokens,
      };
    case actions.auth.SET_COGNITO_CLIENT_INFO:
      return {
        ...state,
        cognitoClientId: action.cognitoClientId,
        clientSecret: action.clientSecret,
      };
    case actions.auth.CLEAR_USER:
      return initialState;
    case actions.auth.SET_ACCOUNTLESS_PURCHASE_EMAIL: 
      return {
        ...state,
        accountlessPurchaseEmail: action.email
      }
    default:
      return state;
  }
};

export default authReducer;
