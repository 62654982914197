import API from "@aws-amplify/api";
import Log from "../utils/Log";
import {
  BBDocResponseObj,
  BBDownloadVersion,
  ChooseYourOwnAdventureChapterData,
  ChooseYourOwnAdventureChoiceData,
  ChooseYourOwnAdventureCustomizationData,
  CreateTxt2ImgGenerationJobDTO,
  DownloadPlatforms,
  ImageGenerationJobCreationResultDTO,
  PostSSOTokensDTO,
  templateType,
  Txt2ImgGenerationJobCreationResultDTO,
} from "../constants/GenPrompTypes";
import Env from "../Env";
import { SharedBit } from "../constants/BBPlatform.types";
const UUID_REGEX = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/
export class BBPlatformClientError extends Error {}


function convertBBAICYOADataToPlatformFormat(projectId: string, input: any) : ChooseYourOwnAdventureCustomizationData {
    console.debug("convert to platform format for cyoa data: ", input)
    // AP: a little hacky but really this whole thing is 
    let output: ChooseYourOwnAdventureCustomizationData = {} as ChooseYourOwnAdventureCustomizationData

    output.projectId = projectId;
    output.badEndText = input.lose.chapterContent;
    output.goodEndText = input.win.chapterContent;
    output.badEndImageFilename = "lose.png";
    output.goodEndImageFilename = "win.png";
    output.title = input.title.title;
    output.subtitle = input.title.mission;
    output.titleImageFilename = "title.png";
    output.iconFilename = "icon.png";
    output.backgroundMusicFilename = "background_music.mp3"; // TODO: figure this out, it's actually NYI

    // make some chapters...
    let chapters : ChooseYourOwnAdventureChapterData[] = []
    for(let ii = 0; ii < input.chapters.length; ii++) {
      let chapter = input.chapters[ii]
      // console.debug("chapter looks like: ", chapter)
      chapter.description = chapter.chapterContent;
      chapter.imageFilename = `chapter${ii}.png`
      let choicesData = chapter.choices
      let choices : ChooseYourOwnAdventureChoiceData[] = []
      for(let jj = 0; jj < choicesData.length; jj++) {
        let choiceData = choicesData[jj]
        let choice : ChooseYourOwnAdventureChoiceData = {
          choiceDescription: choiceData.choiceDescription,
          coinsDelta: choiceData.coinsDelta,
          healthDelta: choiceData.healthDelta,
          resultDescription: choiceData.resultDescription,
        }
        choices.push(choice)
      }
      chapter.choices = choices
      chapters.push(chapter)
    }
    output.chapters = chapters


    return output
  }

// export const fancyTokenFunction = (): DefaultThunkAction<Promise<void>> => {
//   return async (dispatch) => {
//     return  await dispatch(validateToken());
//   }
// }

export const getGenerateAsset = async (
    accessToken:any,
    body: CreateTxt2ImgGenerationJobDTO
  ): Promise<Txt2ImgGenerationJobCreationResultDTO> => {
  try{ 
    const res = await API.post("bb2URL", "/assetgeneration/txt2img", {
      body: body,
      headers: {
"webgenai-client-version": true,
      "Content-Type": "application/json",
      Token: accessToken
      },
      response: true
    })

    return res.data.payload;
  } catch (error) {
    Log.error(error, "error caught in getGenerateAsset");
    throw error;
  }
}

export const getAssetGenerationProgress = async (
    accessToken:any,
    jobId: string
  ): Promise<ImageGenerationJobCreationResultDTO> => {
  try{ 
    
    const res = await API.get("bb2URL", `/assetgeneration/job/${jobId}/progress`, {
      headers: {
"webgenai-client-version": true,
      "Content-Type": "application/json",
      Token: accessToken
      },
      response: true
    })

    return res.data.payload;

  } catch(error) {
    Log.error(error, "error caught in getAssetGenerationProgress");
    throw error
  }
}

export const generateClipdropAsset = async ( 
    accessToken:any,
     body: CreateTxt2ImgGenerationJobDTO
  ): Promise<Txt2ImgGenerationJobCreationResultDTO> =>  {

  try { 
    const res = await API.post("bb2URL", "/assetgeneration/clipdrop/txt2img", {
      body: body,
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data.payload;
  } catch(error){ 
    Log.error(error, "error caught in generateClipdropAsset"
    )
    throw error
  }
}

export const emplaceCYOAResource = async ( 
  accessToken:any,
  projectId: string,
  filename: string,
  url: string):  Promise<any> => {
  let data = {
      projectId: projectId,
      filename: filename,
      url: url,
  }

  try{
    const res = await API.post("bb2URL", `/cyoa/emplace`, {
      body: data,
      headers: {
"webgenai-client-version": true,
      "Content-Type": "application/json",
      Token: accessToken
      },
      response: true
    })

    return res.data.payload;

  }catch(error){
    Log.error(error, "error caught in emplaceCYOAResource")
    throw error
  }
}

export const createCYOABBDoc = async (
    accessToken:any,
    projectId: string,
    gameData: any
  ): Promise<any>  => {
    let data = convertBBAICYOADataToPlatformFormat(projectId, gameData)

  try{ 
    const res = await API.post("bb2URL", `/cyoa/cyoa`, {
      body: data,
      headers: {
"webgenai-client-version": true,
      "Content-Type": "application/json",
      Token: accessToken
      },
      response: true
    })

    return res.data.payload;
  } catch(error) {
    Log.error(error, "error caught in createCYOABBDoc")
    throw error
  }
}

export const createBBDocRandomFile = async (body: {
    accessToken:any;
    characterImage: string;
    obstacleImage: string;
    backgroundImage: string;
  }): Promise<any>  => {
  const index = Math.floor(Math.random() * Object.keys(templateType).length);
  const randomTemplateType = Object.values(templateType)[index];

  try{
    const res = await API.post("bb2URL", `/assetgeneration/createbbdoc`, {
      body: {
        ...body,
        templateType: templateType[randomTemplateType],
        //templateType: templateType["WORLD"]
      },
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: body.accessToken
      },
      response: true
    })

    return res.data.payload;

  }catch(error) {
    Log.error(error, "error caught in createBBDocRandomFile")
    throw error
  }
}

export const getAssetImages = async (
    accessToken:any,
    jobId: string
  ): Promise<{ jobId: string; presignedUrls: string[] }> =>  {
    try{
      const res = await API.get("bb2URL", `/assetgeneration/job/${jobId}/result`, {
        headers: {
"webgenai-client-version": true,
          "Content-Type": "application/json",
          Token: accessToken
        },
        response: true
      })

      return res.data.payload;
    } catch(error) {
      Log.error(error, "error caught in getAssetImages")
      throw error
    }
  }

export const downloadBuildboxClient = async ( 
    accessToken:any,
     platformDesired: any,
    version: keyof typeof BBDownloadVersion
  ) => {

  try{ 
    if (!platformDesired) {
      // try to auto detect platform from browser
      const platform = (window as any).navigator.platform;

      if (platform) {
        const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
        const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

        const foundMatch = (str: string) => str.includes(platform);
        if (windowsPlatforms.some(foundMatch)) {
          platformDesired = DownloadPlatforms.WINDOWS;
        } else if (macosPlatforms.some(foundMatch)) {
          platformDesired = DownloadPlatforms.MAC;
        } else {
          console.debug(
            "unable to automatically determine download platform from ",
            platform
          );
          platformDesired = DownloadPlatforms.UNKNOWN;

          const dataLayer =
            ((window as any)["dataLayer"] && (window as any)["dataLayer"]) ||
            [];
          dataLayer.push({
            event: "couldNotDeterminePlatformDownload",
            payload: true,
          });
        }
      } else {
        console.debug("unable to automatically determine download platform");
        platformDesired = DownloadPlatforms.UNKNOWN;

        const dataLayer = ((window as any).dataLayer =
          (window as any).dataLayer || []);
        dataLayer.push({
          event: "platformInfoNotAvailableDownload",
          payload: true,
        });
      }
    }

    // download!
    if (platformDesired === DownloadPlatforms.MAC) {

      //TODO: Update GTM tags to track BB2

      const downloadLink = BBDownloadVersion[version].MAC;
      // const downloadType = BBDownloadVersion[version].APP;

      (window as any).location.href = downloadLink;

      const dataLayer = ((window as any).dataLayer =
        (window as any).dataLayer || []);
      dataLayer.push({
        event: "macDownload",
        payload: true,
      });

      // const savedGoogleClientId = localStorage.getItem("googleClientId");
      // BBPlatformClient.sendDownloadAnalytics(savedGoogleClientId, downloadType);

      return(downloadLink);
    } else if (platformDesired === DownloadPlatforms.WINDOWS) {

      //TODO: Update GTM tags to track BB2

      const downloadLink = BBDownloadVersion[version].WIN;
      // const downloadType = BBDownloadVersion[version].APP;

      (window as any).location.href = downloadLink;

      const dataLayer = ((window as any).dataLayer =
        (window as any).dataLayer || []);
      dataLayer.push({
        event: "winDownload",
        payload: true,
      });

      return (downloadLink);
    } else {
      console.debug("attempted download on non-mac/win platform");

      const dataLayer = ((window as any).dataLayer =
        (window as any).dataLayer || []);
      dataLayer.push({
        event: "unknownPlatformDownload",
        payload: true,
      });

      return (false);
    }
  } catch( error) {
    Log.error(error, "error caught in downloadBuildboxClient")
    throw error
  } 

} 
    
export const createUserDownloadsRequest = async (
    accessToken:any,
    clientId: any, version: string) => {
    let error;
    for (let ii = 0; ii < 5; ii++) {
      try {
        let resp = await sendUserDownloadRequestAnalytics(
          accessToken,
          clientId,
          version
        );
        return resp;
      } catch (err) {
        console.error("cudr error: ", err);
        error = err;
      }
    }

    console.debug("throw cudr error: ", error);
    throw error;
  }

export const sendUserDownloadRequestAnalytics = async(
    accessToken:any,
    clientId: any,
    version: string
  ): Promise<any> => {
  try{
    const res = await API.post("userAccountManagementURL", `/analytics/download`, {
      body: {
        clientId: clientId,
        app: version,
      },
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data.data;
      
  }catch(error) {
    Log.error(error, "error caught in sendUserDownloadRequestAnalytics")
    throw error
  }
}


export const sendDownloadAnalytics = async ( accesssToken:string, googleClientId: any, version: any) => {
    try {
      console.debug(googleClientId, "Downloads Component: send client id");
      console.debug(version, "Downloads Component: app:", version);

      const analyticsResponse =
        await createUserDownloadsRequest(
          accesssToken,
          googleClientId,
          version
        );
      console.debug(
        analyticsResponse,
        "Downloads Component: Response from /createUserDownloadsRequest"
      );
    } catch (error) {
      console.error(
        error,
        "Downloads Component: Error caught from sendDownloadAnalytics"
      );
    }
  }

export const getUserBBDocs = async (accessToken:any, limit:number=10,offset:number=0): Promise<BBDocResponseObj[]> =>  {
  try{ 
    const res = await API.get("bbworldUrl", `/bbdoc/owned?runtime=BBClassic&limit=${limit}&offset=${offset}`, {
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data.payload.bits;

  }catch(error) {
    Log.error(error, "error caught in getUserBBDocs")
    throw error
  }
}

export const getBBDocDownloadLink = async (accessToken: any, bbdocId:string):Promise<string> =>  {
  const validUUID = UUID_REGEX.test(bbdocId) // make sure it's a UUID and not some sill XSS attack
  if (!validUUID) {
    throw new BBPlatformClientError("Invalid BBDoc ID")
  }
  try{ 
    const res =  await API.get("bb2URL", `/assetgeneration/bbdoc/download/${bbdocId}`, {
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Tokoen: accessToken
      },
      response: true
    })

    return res.data.payload.url;

  } catch (error) {
    Log.error(error, "error caught in getBBDocDownloadLink")
    throw error
  }
}


export const openBBWorld= (bbDocId:string=""):void => {
  console.debug("play bit", `${Env.BB_DEEP_LINK}${bbDocId}`)
  window.location.replace(`${Env.BB_DEEP_LINK}${bbDocId}`);
}

export const getSSOToken = async ( accessToken:any, ssoTokenDTO:PostSSOTokensDTO ): Promise<string> => {
  try{ 
    const res = await API.post("userAccountManagementURL", `/ssoTokens`, {
      body: {
        ...ssoTokenDTO
      },
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data.payload.id;
  } catch(error){
    Log.error(error, "error caught in getSSOToken")
    throw error
  }
}


export const cyoaNewId = async (accessToken:any):  Promise<string> =>
{
  try{
    const res = await API.post("bb2URL", `/cyoa/newId`, {
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data.payload.projectId;

  } catch(error) {
    Log.error(error, "error caught in cyoaNewId")
    throw error
  }
}

export const cyoaConfirmComplete = async (accessToken:any, projectId:string): Promise<any> => {
  try{
    const res = await API.post("bb2URL", `/cyoa/complete`, {
      body: {
        projectId
      },
      headers: {
        "webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data.payload;

  } catch(error) {
    Log.error(error, "error caught in cyoaConfirmation")
    throw error
  }
}

export const  getCYOABBDocDownloadLink = async ( accessToken:any, projectId:string):Promise<{url:string, expiresIn:string}> => {
  const validUUID = UUID_REGEX.test(projectId) // make sure it's a UUID and not some sill XSS attack
  if (!validUUID) {
    throw new BBPlatformClientError("Invalid BBDoc ID")
  }

  try{ 
    const res = await API.get("bb2URL", `/cyoa/bbdoc/download/${projectId}`, {
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return { url: res.data.payload.url, expiresIn: res.data.payload.expiresIn };

  } catch(error) {
    Log.error(error, "error caught in getCYOABBDocDownloadLink")
    throw error
  }
}

export const getUserData = async (accessToken:any ): Promise<any> => {

  try{ 
    const res = await API.post("userAccountManagementURL", `/webclientLogin`, {
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data.payload;

  } catch(error) {
    Log.error(error, "error caught in getUserData")
    throw error
  }
}
export const getBalance = async (accessToken:any): Promise<{ balance: number,  creditsUsed: number}> => {
  try{ 

    const res = await API.get("bb2URL", `/cyoa/balance`, {
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data.payload;

  } catch(error) {
    console.error(error, "error caught in getBalance")
    throw error
  }
}
  
export const getFirebaseShortenDeepLink = async ( accessToken:any, bitId:string): Promise<string> => {
  try{ 
    const res = await API.get("bbworldUrl", `/firebaseShortLink/${bitId}`, {
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
        },
        response: true
      })

    return res.data.payload.url;
  } catch(error) {
    // Log.error(error, "error caught in getFirebaseShortenDeepLink")
    throw error

  }
}

export const cyoaGameAsJson = async (accessToken: any, userInput: string, numberOfChapters: number = 10): Promise<any> => {

  try{ 
    const res = await API.post("bbaiURL", `/cyoa/game_as_json`, {
      body: {
        "openai_configs": {
          "model_name": Env.CYOA_OPENAI_MODEL,
          "temperature": Env.CYOA_OPENAI_TEMPERATURE,
        },
        "user_input": userInput,
        "number_of_chapters": numberOfChapters
      },
      headers: {
"webgenai-client-version": true,
        "Content-Type": "application/json",
        Token: accessToken
      },
      response: true
    })

    return res.data;

  }catch(error) {
    Log.error(error, "error caught in cyoaGameAsJson")
    throw error
  }
}

export const getAllSharedBits = async ( offset: number = 0):Promise<SharedBit[]> => {
  try {
    const res = await API.get("bbworldUrl", `/globalBits?offset=${offset}&limit=100`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    // console.log(res.payload)
    return res.payload.bits
  }catch(error) {
    Log.error(error, "error caught in getAllSharedBits")
    throw error
  }
}