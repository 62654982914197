import React from 'react';
import { Helmet } from 'react-helmet';

const OneTrust = () => {
  return (
    <>
      {process.env.REACT_APP_STAGE === "production" ? 
      null
      // (
      //   <Helmet>
      //     <script type="text/javascript" src="https://cdn.cookielaw.org/consent/018e3ec4-2c2c-76fa-8247-8fcfe4448488"></script>
      //     <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" data-domain-script="018e3ec4-2c2c-76fa-8247-8fcfe4448488"></script>
      //     <script type="text/javascript">
      //       {`function OptanonWrapper() { }`}
      //     </script>
      //   </Helmet>
      // ) 
      : 
      (
        <Helmet>
          <script type="text/javascript" src="https://cdn.cookielaw.org/consent/018e3ec4-2c2c-76fa-8247-8fcfe4448488-test"></script>
          <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" data-domain-script="018e3ec4-2c2c-76fa-8247-8fcfe4448488-test"></script>
          <script type="text/javascript">
            {`function OptanonWrapper() { }`}
          </script>
        </Helmet>
      )
      }
    </>
  );
};

export default OneTrust;
