import React from "react"
import { Progress } from "antd";
import { FunctionComponent } from "react";

interface CircularProgressProps {
    progress?: number;
}
 
const CircularProgress: FunctionComponent<CircularProgressProps> = ({progress = 75}) => {
    
    return (
        <Progress type="circle" percent={progress} strokeWidth={10} size={50} strokeColor={{
            '0%': '#F9AA30',
            '100%': '#F9AA30',
          }} />
    );
}
 
export default CircularProgress;
