export const findCookieValue = (cookieName) => {
  const foundCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieName));
  //console.log("foundCookie", foundCookie);
  if (foundCookie?.length) {
    return foundCookie.split("=")[1];
  }
  return false;
};

export const setXsollaCookie = async (referralCode) => {
  let daysUntilExp = 365;
  const cookieName = "tracking_id";

  let date = new Date();
  date.setTime(date.getTime() + daysUntilExp * 24 * 60 * 60 * 1000);

  document.cookie =
    cookieName + "=" + referralCode + "; expires=" + date.toUTCString() + ";";
};
