import { formatUNIXTimestamp } from "../utils/helperUtils";

//used to format plan information for user account subscription pages

export function mapToPlan(
  subscriptions: bb.model.account.ISubscriptionInfo[],
  plans: bb.model.account.IStripePlans,
  invoices: bb.model.account.IStripeInvoice[],
  devices: bb.model.account.IDevices[]
): bb.model.account.ICurrentPlan[] {
  const hasSameIds = hasDuplicateIds(subscriptions);

  return subscriptions.map((subscription) => {
    let billing;
    let checkStr = subscription.planInfo.product?.toLowerCase()
    let foundPlan: bb.model.account.IStripePlan | null | undefined
    let planName: string | undefined

    if (subscription.billingInfo) {
      billing = {
        brand: subscription.billingInfo.cardInfo.brand?.toUpperCase(),
        expiryMonth: subscription.billingInfo.cardInfo.expMonth,
        expiryYear: subscription.billingInfo.cardInfo.expYear,
        lastFour: `**** **** **** ${subscription.billingInfo.cardInfo.last4}`,
      };
    }

    // soundbox comes in here for account subscription tab, but it's different
    if(checkStr === "soundbox") {
      foundPlan = plans["SoundboxPlan"]
      // TODO BUILDBOX4 Release, this used to say 'Buildbox 3' instead of 'Soundbox' but that was probably wrong, right?
      planName = "Soundbox " + foundPlan?.name
      return {
        subscriptionId: subscription.id,
        product: subscription.planInfo.product,
        planName: planName,
        seatQuantity: "0",
        totalSeats: "0",
        status:
          subscription.planInfo.status[0].toUpperCase() +
          subscription.planInfo.status.substring(1),
        billingInterval: mapBillingInterval(subscription.planInfo.interval),
        nextPaymentDate: formatUNIXTimestamp(
          subscription.currentPeriodEnd * 1000
        ),
        paymentAmount: ((subscription.planInfo.amount || 0) / 100).toString(),
        billingInfo: billing,
        selectedPlanSpecs: {
          // supportedModules: mapSupportedModules(foundPlan),
          // availableWorlds: foundPlan?.worlds,
          // availableSections: foundPlan?.sections,
          // brandedSplashScreen: foundPlan?.isCustomSplashScreenAllowed,
        },
        devices: devices,
        upgradeAvailable: foundPlan?.isPlanUpgradeAvailable,
        duplicateIds: hasSameIds,
        subscriptionTier: foundPlan?.name,
      };
    }

    // handle Buildbox products similarly, since they have plan feature lists
    if(checkStr === "bb4") {
      foundPlan = plans["BB4Plan"]
      planName = "Buildbox 4 " + foundPlan?.name
    }    
    else if(checkStr === "bb3") {
      foundPlan = plans["BB3Plan"]
      planName = "Buildbox 3 " + foundPlan?.name
    }
    else if(checkStr === "bb2") {
      foundPlan = plans["BB2Plan"]
      planName = "Buildbox Classic " + foundPlan?.name
    }
    else {
      let errMsg = "unrecognized product in plan info: " + subscription.planInfo.product
      console.error(errMsg)
      throw new Error(errMsg)
    }
    
    return {
      subscriptionId: subscription.id,
      product: subscription.planInfo.product,
      planName: planName,
      seatQuantity: "0",
      totalSeats: "0",
      status:
        subscription.planInfo.status[0].toUpperCase() +
        subscription.planInfo.status.substring(1),
      billingInterval: mapBillingInterval(subscription.planInfo.interval),
      nextPaymentDate: formatUNIXTimestamp(
        subscription.currentPeriodEnd * 1000
      ),
      paymentAmount:
        hasSameIds && subscription.planInfo.product === "BB2"
          ? "0.00"
          : ((subscription.planInfo.amount || 0) / 100).toString(),
      billingInfo: billing,
      selectedPlanSpecs: {
        supportedModules: mapSupportedModules(foundPlan),
        availableWorlds: foundPlan?.worlds,
        availableSections: foundPlan?.sections,
        brandedSplashScreen: foundPlan?.isCustomSplashScreenAllowed,
      },
      devices: devices,
      upgradeAvailable: foundPlan?.isPlanUpgradeAvailable,
      duplicateIds: hasSameIds,
      subscriptionTier: foundPlan?.name,
    };
  });
}

/*
 * devices payload from backend has been updated:
    const payloadBB2: UserMachineInfo[] = await this.machineInfoBB2.retrieveMachines(userId, activeBool)
    const payloadBB3: UserMachineInfo[] = await this.machineInfoBB3.retrieveMachines(userId, activeBool)
    const payloadBB4: UserMachineInfo[] = await this.machineInfoBB4.retrieveMachines(userId, activeBool)
    const payloadSoundbox: UserMachineInfo[] = await this.machineInfoSoundbox.retrieveMachines(userId, activeBool)
    const fullPayload = {
      BB2: payloadBB2,
      BB3: payloadBB3,
      BB4: payloadBB4,
      Soundbox: payloadSoundbox,
    }
  */
export function mapToFreePlan(
  plans: bb.model.account.IStripePlans,
  devices: { [key: string]: bb.model.account.IDevices[] }
): bb.model.account.ICurrentPlan[] {
  // filter out "Soundbox Free" plan since it doesn't actually confer any benefit
  const plansArray = Object.entries(plans).filter((plan) => plan[1] && !(plan[0] === "SoundboxPlan" && plan[1].name.toLowerCase().includes("free")));

  const billing = {
    brand: "",
    expiryMonth: 0,
    expiryYear: 0,
    lastFour: "N/A",
  };

  return plansArray.map((plan) => {
    const planDetail = plan[1];

    let planType = plan[0]
    let product: string | undefined
    let planName: string | undefined
    if(planType === "BB4Plan") {
      product = "BB4"      
      planName = "Buildbox 4 " + planDetail.name
    }    
    else if(planType === "BB3Plan") {
      product = "BB3"      
      planName = "Buildbox 3 " + planDetail.name
    }
    else if(planType === "BB2Plan") {
      product = "BB2"
      planName = "Buildbox Classic " + planDetail.name
    }
    else if(planType === "SoundboxPlan") {
      product = "Soundbox"
      planName = "Soundbox " + planDetail.name
    }
    else {
      console.error("unexpected plan info: ", plan)
      product = "Unknown"
      planName = "" + planDetail.name
    }
    let planDevices : bb.model.account.IDevices[] = []
    if(devices[product]) {
      planDevices = devices[product]
    }

    return {
      subscriptionId: "",
      product: product,
      planName: planName,
      seatQuantity: "0",
      totalSeats: "0",
      status: "Active",
      billingInterval: "-",
      nextPaymentDate: "-",
      paymentAmount: "0",
      billingInfo: billing,
      selectedPlanSpecs: {
        supportedModules: mapSupportedModules(planDetail),
        availableWorlds: planDetail.worlds,
        availableSections: planDetail.sections,
        brandedSplashScreen: planDetail.isCustomSplashScreenAllowed,
      },
      devices: planDevices,
      upgradeAvailable: planDetail.isPlanUpgradeAvailable,
      subscriptionTier: planDetail.name,
    };
  });
}

export function mapBillingInterval(interval: string): string {
  switch (interval) {
    case "year":
      return "Annual";
    case "month":
      return "Monthly";
    default:
      return interval;
  }
}

export function mapSupportedModules(plans: any | null): string[] {
  const modules = [];
  for (let supportedMod in plans) {
    if (plans[supportedMod]) {
      switch (supportedMod) {
        case "exportIOS":
          modules.push("IOS");
          break;
        case "exportOSX":
          modules.push("OSX");
          break;
        case "exportWin32exe":
          modules.push("Windows EXE");
          break;
        case "exportAndroid":
          modules.push("Android");
          break;
        case "exportAmazon":
          modules.push("Amazon");
          break;
      }
    }
  }

  return modules;
}

export function hasDuplicateIds(
  subscriptions: bb.model.account.ISubscriptionInfo[]
) {
  const idArray = subscriptions.map((sub) => sub.planInfo.id);
  return new Set(idArray).size !== idArray.length;
}
