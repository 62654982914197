//add keys for dev and prod environments here:
//adding just dev keys for now:
import Env from "../Env";

export const aws_config = {
  s3: {
    REGION: process.env.REACT_APP_REGION,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_URL,
  },
  analyticsGateway: {
    URL: process.env.REACT_APP_API_ANALYTICS_URL,
  },
  plansGateway: {
    URL: process.env.REACT_APP_API_PLAN_MANAGEMENT_URL,
    // URL: process.env.REACT_APP_LOCAL_PLANMGT_URL
  },
  adManagerGateway: {
    URL: process.env.REACT_APP_API_AD_MANAGER_URL,
  },
  assetManagementGateway: {
    URL: process.env.REACT_APP_ASSET_MANAGEMENT_GATEWAY_URL,
  },
  bbworldGetway: {
    URL: process.env.REACT_APP_BUILD_BOX_WORLD_GATEWAY_URL,
  },
  pseudo: {
    URL: process.env.REACT_APP_PSEUDO_URL,
  },
  bb2Gateway: {
    URL: Env.BB2D_BASE_URL,
  },
  bbaiGateway: {
    URL: Env.BBAI_BASE_URL,
  },
  assetMangerGateway: {
    URL: Env.ASSET_MANAGER_BASE_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    COOKIE_DOMAIN: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN || "localhost",
  },
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN_URL,
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_URL,
    responseType: "code",
  },
};
