import React, { useEffect, useRef, useState } from 'react'
import { bbaiStoryGamePromptOptions, bbaiStoryGamePromptStepStatus, bbaiUser } from '../constants/bbai.prompts'
import PromptMessage from './PromptMessage'
import currentStoryGame from "../../../selectors/currentStoryGame";
// import { BBAIPlatformClient } from '../../../api/BBAIPlatform'
import { customEventDataLayerTrigger } from '../../../api/GoogleTagManager'
import currentUserInfo from "../../../selectors/currentUserInfo";
import { useDispatch, useSelector } from 'react-redux';
import { nextStage, setErrorState, setGeneratedCYOAGameData, setPromptStepState } from '../../../actions/storyGame';
import { validateToken } from '../../../actions/auth';
import { cyoaGameAsJson } from '../../../api/storyGame';

const GeneratingGameDataCYOA = () => {

  const bbaiStore = useSelector(currentStoryGame)
  const userStore = useSelector(currentUserInfo);
  const generateDataCYOA = useRef<boolean>(false) // make sure the useFfect fetch call only runs once
  const startTimer = useRef(false)
  const [generatedDataSuccess, setGeneratedDataSucesss] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => { 
    let interval: any;
    /// TODO: generate the game data here, then next step
    
    if(bbaiStore.userStoryPrompt.length > 0 && generateDataCYOA.current === false && !bbaiStore.completed){
      const bodyGenAI = {
        UserID: userStore.uuid,
        genai_question: bbaiStoryGamePromptOptions["INITIAL"].prompt,
        genai_user_response: bbaiStore.userStoryPrompt,
        genai_skipped: false,
      }
      customEventDataLayerTrigger("Web GenAI Prompt", userStore.uuid, bodyGenAI)
      startTimer.current = true
      generateCYOAGameData(bbaiStore.userStoryPrompt)
      
    }

    if(bbaiStore.completed){
      console.log("is it completed? ", bbaiStore.completed)
      dispatch(nextStage())
    }

    return () => {
      generateDataCYOA.current = true
      clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [])

  const generateCYOAGameData = async(
    userPrompt: string,
  ) => {
      
    dispatch(setPromptStepState(bbaiStoryGamePromptStepStatus.GENERATING_GAME_DATA))
    const numberOfChapters = 10
    // even this request can take awhile, I'm nervous to await it... ?
    // console.log("request cyoa with user input: ", userPrompt, " and ", numberOfChapters, " chapters")
    // TODO: catch and handle error?

    try{
      const refreshedToken = await dispatch(validateToken());
      const genJob = await cyoaGameAsJson(refreshedToken, userPrompt, numberOfChapters)
      console.log("got game data: ", genJob)
      dispatch(setGeneratedCYOAGameData(genJob))
      
      customEventDataLayerTrigger("Web GenAI Generate", userStore.uuid)
      
      setGeneratedDataSucesss(true)
      dispatch(nextStage())  
      // AP: move on to generate assets automatically? how?
      console.log("got game data; TODO: generate assets from it")  
    } catch(err) {
      console.error("ERROR: ", err)
      dispatch(setErrorState({
        title: "Error Generating Story Game Data",
        message: "There was an error generating your Story game data. Please try again later.",
      }));
      startTimer.current = false
      // unauthorizedErrorHandler?.();
      // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.ERROR_RESTART_REGENERATE)
    }
  }
  return (
    <>
     <PromptMessage promptStage={bbaiStoryGamePromptStepStatus.GENERATING_GAME_DATA} message={bbaiStoryGamePromptOptions.GENERATING_GAME_DATA.prompt} user={bbaiUser}
      loading={startTimer.current} successReport={generatedDataSuccess}
      error={bbaiStore.error !== undefined}
      completed={bbaiStore.completed}
      />
    </>
  )
}

export default GeneratingGameDataCYOA