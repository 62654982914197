import React, {ReactElement, useEffect, useState} from 'react';
import { BBPlatformClient } from '../../../api/BBPlatform';
import currentStoryGame from "../../../selectors/currentStoryGame";
import { Col, QRCode, Row } from 'antd';
import styles from "../../../styles/PromptMessage.module.css";
import { bbaiStoryGamePromptOptions, bbaiUser } from '../constants/bbai.prompts';
import AnimatedButton from './AnimatedButton';
import downloadStyles from "../../../styles/DownloadLink.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { setBBDocUrl, setErrorState } from '../../../actions/storyGame';
import useIsMobile  from '../../../hooks/useIsMobile';
import Env from '../../../Env';
import { validateToken } from '../../../actions/auth';
import { getCYOABBDocDownloadLink, getFirebaseShortenDeepLink } from '../../../api/storyGame';
import PreviewBit from './PreviewBit';

enum FileType {
    BBCLASSIC_INSTALLER = "bbclassic_installer",
    BBDOC = "bbdoc",
}

export const DownloadBBDocCYOA = (): ReactElement => {
  const [bbdoc, setBBDoc] = useState<string | null>(null)
  // const [urlExpireTime, setUrlExpireTime] = useState<number>(0)
  const {isMobile} = useIsMobile()
  const bbaiStore = useSelector(currentStoryGame)
  const dispatch = useDispatch()
  const [dynamicLink, setDynamicLink] = useState<string | null>(null)

  useEffect(() => {
    getBBDOC()
    // eslint-disable-next-line
  }, [])

  const downloadBBDOC = async () => {
    // console.log("testing date logic", urlExpireTime < (new Date().getTime() ))
    if (!bbdoc) {
      console.log("bbdoc no longer exists, regenerating", bbdoc)
      // unauthorizedErrorHandler?.();
      await getBBDOC();
      return;

    }
    const a = document.createElement("a");
    a.download = "StoryGame.bbdoc";
    a.href = bbdoc;
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const getBBDOC = async (): Promise<void> => {
    try {
      if( bbaiStore.bbworldBBDocId !== ""){
      const refreshedToken = await dispatch(validateToken());

        const res = await getCYOABBDocDownloadLink(refreshedToken, bbaiStore.projectId)
        const deepLink = await getFirebaseShortenDeepLink(refreshedToken, bbaiStore.bbworldBBDocId)
        console.debug("firebase shorten deepLink: ", deepLink)
        setBBDoc(res.url)
        setDynamicLink(deepLink)
        dispatch(setBBDocUrl(res.url))
      } else {
        console.error("error: bbdoc id is empty")

      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleClickDownloadBBDOC = async (fileType: FileType) => {
    try{

      if (isMobile) {
        if(dynamicLink){
          window.location.replace(dynamicLink)
        } else {
          BBPlatformClient.openBBWorld(bbaiStore.bbworldBBDocId)
        }
        
      } else if (fileType === FileType.BBDOC) {
        downloadBBDOC();
      } else if (fileType === FileType.BBCLASSIC_INSTALLER) {
        await BBPlatformClient.downloadBuildboxClient(null, "BB2");
      }
      else {
        console.error("error: unexpected state for download click")
      }
    } catch(error){
      console.error("error: ", error)
      // TODO: handle error
      dispatch(setErrorState({
        title: "Error generating Story Game download",
        message: bbaiStoryGamePromptOptions[bbaiStore.promptStepStatus]?.errPrompt,
      }));
    }
  };

  const MobileRender = () => {
    return (
      <div>
        <p>{bbaiStoryGamePromptOptions["COMPLETE_MOBILE"].prompt}</p>
        <AnimatedButton text={"Play Bit!"} handleClick={playBit} />
      </div>
    );
  };

  const playBit = () => {
    // sendCustomEvent("play_bit")
    handleClickDownloadBBDOC(FileType.BBDOC);
    // window.location.replace( `${Env.BB_DEEP_LINK}${bbdocBitId ? bbdocBitId : ""}`);
  }

  const DesktopRender = () => {
    return (
      <div>
        <div style={{ textAlign: "center"}}>
          With Buildbox World you can explore and play exciting bits…
          <div style={{margin: "12px"}}/>
          <div  className={styles.qrContainer}>
           { dynamicLink ? <QRCode value={dynamicLink} bgColor={"white"}/>  :  <img src={Env.QR_BB_WORLD} alt="buildbox world qr"/> }
          </div>
        </div>
        <p>{bbaiStoryGamePromptOptions["COMPLETE"].prompt}</p>
        <ol style={{textAlign: "left", padding: ".5em", marginTop: "1em"}}>
          <li>
            Download and install Buildbox Classic:
            <br />{" "}
            <div className={downloadStyles.downloadLink}>
              <img className={downloadStyles.downloadLinkIcon} width={18} src={"https://frontend-assets.buildbox.com/web-gen-ai/BuildboxClassicLogo.svg"} alt="Buildbox Classic Installer" /> <span className={styles.fancyText} onClick={() => handleClickDownloadBBDOC(FileType.BBCLASSIC_INSTALLER)}>Buildbox Classic Installer</span>
            </div>
          </li>
          <li>
            Download your project file:
            <br />{" "}
            <div className={downloadStyles.downloadLink}>
              <img className={downloadStyles.downloadLinkIcon} width={18} src={"https://frontend-assets.buildbox.com/web-gen-ai/bbdoc@2x.png"} alt="StoryGame.bbdoc" /> <span  className={styles.fancyText} onClick={() => handleClickDownloadBBDOC(FileType.BBDOC)}>Your game.bbdoc</span>
            </div>
          </li>
          <li>Open the .bbdoc file in Buildbox Classic to edit it today!</li>
        </ol>
      </div>
    );
  };
  
  return (
    <>
     <Row
        className={`${styles.promptMessage} ${styles.promptFeedbackBBAI}`}
      >
        <Col xs={4} sm={3} md={3} lg={2} xl={2}>
          <img className={styles.senderImg} src={bbaiUser.pic} alt="sender" />
        </Col>
        <Col xs={20} sm={21} md={21} lg={22} xl={22}>
           <PreviewBit/>
          {isMobile ? <MobileRender /> : <DesktopRender/>}
        </Col>
      </Row>
    </>
  )
}
