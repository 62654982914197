import { WorkState } from "./../reducers/models";
import { DefaultThunkAction } from "./shared";
import { AnyAction } from "redux";
import actions from "../constants/actions.json";
import { waitForGAClientId } from "../utils/analyticsUtils";
import {
  createUserRegistrationRequest,
  createUserPurchaseRequest,
} from "../api/analytics";
import { setAnalyticsWorkState } from "./current";
import Log from "../utils/Log";

export function setAnalyticsRegistrationEvent(
  email: string,
  uuid: string,
  clientId: string | null,
  productType: string | null,
  deviceType: string | null
): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(setAnalyticsWorkState(WorkState.Loading));

    try {
      await waitForGAClientId((googleClientId: string | null) =>
        createUserRegistrationRequest(
          email,
          googleClientId,
          uuid,
          productType,
          deviceType
        )
      );
      dispatch(setAnalyticsWorkState(WorkState.None));
    } catch (error:any) {
      Log.error(error, "error caught in setAnalyticsRegistrationEvent");
      dispatch(setAnalyticsWorkState(WorkState.Error));
      throw error;
    }
  };
}

export function setAnalyticsPurchaseEvent(
  email: string,
  uuid: string,
  planType: string,
  planInterval: string,
  product: string
): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(setAnalyticsWorkState(WorkState.Loading));

    try {
      await waitForGAClientId((googleClientId: string | null) =>
        createUserPurchaseRequest(
          email,
          googleClientId,
          uuid,
          planType,
          planInterval,
          product
        )
      );

      const dataLayer = (window.dataLayer = window.dataLayer || []);
      dataLayer.push({
        event: "successfulPayment",
        payload: true,
      });
      dispatch(setAnalyticsWorkState(WorkState.None));
    } catch (error:any) {
      Log.error(error, "error caught in setAnalyticsPurchaseEvent");
      dispatch(setAnalyticsWorkState(WorkState.Error));

      throw error;
    }
  };
}

export function setGAClientId(gaClientId: string | null): AnyAction {
  return {
    type: actions.auth.SET_GA_CLIENT_ID,
    gaClientId,
  };
}
