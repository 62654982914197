import Env from "../Env";

export interface CreateTxt2ImgGenerationJobDTO{
  prompt: string ;
  costInVC:number ;
  disableSuggestedOptimizations: boolean ;
  jobId: string ;
  width: number ;
  height: number ;
  seed: number;
  removeBackground: boolean;
  webCYOASaveCopyToS3Filename?: string|undefined
  webCYOAProjectId?: string|undefined
  projectId?:string | undefined,

  // note that development has consistently been adding more potential params here, see bb-platform AssetGenerationDTO for the full list
}


export interface Txt2ImgGenerationJobCreationResultDTO {
  job: Txt2ImgGenerationJobDTO
  buildbux: {
    newBalance: number;
    oldBalance: number;
  };
  uploadUrl?: string;
}

export interface Txt2ImgGenerationJobDTO {
  jobId:string
  userId:string
  prompt:string
  userPrompt:string
  userNegativePrompt:string
  userDisableSuggestedOptimizations:boolean
  userRemoveBackground:boolean
  costInVC:number
  status: ImageGenerationJobStatus
  progress:number
  createdAt:string
  updatedAt:string
  hasInputImage:boolean
  originalRequest: object|null
}


export enum ImageGenerationJobStatus {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
}

export interface GenAssetsStateDTO {
  charAsset: Txt2ImgGenerationJobCreationResultDTO | null;
  enemyAsset: Txt2ImgGenerationJobCreationResultDTO | null;
  bgAsset: Txt2ImgGenerationJobCreationResultDTO | null;
}

export interface ImageGenerationJobDTO {
  Id: string
  UserId: string;
  Status: ImageGenerationJobStatus;
  Progress: number;
  Prompt: string;
  CostInVC: number;
  Phase: string;
  ProgressInPhase: number;
  ErrorCode: string;
  Extra: string;
  TimeToComplete: number;
  UserMessage: string;  
  Finalized: boolean
  CreatedAt: Date;
  UpdatedAt: Date;
  UserPrompt: string;
  UserNegativePrompt: string;
  UserRemoveBackground: boolean  
  UserDisableSuggestedOptimizations: boolean
  JobRequestJson: string
  HasInputImage: boolean  
  IsSoftDeleted: boolean    
}

export interface ImageGenerationJobCreationResultDTO {
  ETA: number;
  ImageGenerationJob: ImageGenerationJobDTO;
  Queue: number
}

export enum templateType {
    SHOOTER = "SHOOTER",
    WORLD = "WORLD",
    PORTAL = "PORTAL",
    JETPACK = "JETPACK"
}


export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export enum BBDocBackButtonPosition {
  TOP_LEFT = "TopLeft",
  TOP_CENTER = "TopCenter",
  TOP_RIGHT = "TopRight",
  LEFT = "Left",
  CENTER = "Center",
  RIGHT = "Right",
  BOTTOM_LEFT = "BottomLeft",
  BOTTOM_CENTER = "BottomCenter",
  BOTTOM_RIGHT = "BottomRight",
}

export enum BBDocRuntime {
  BBCLASSIC = "BBClassic",
  BB3 = "BB3",
  BB4 = "BB4",
}
export interface UploadBBDocDTO {
  displayName: string
  bytes: number
  shareGlobally?: string
  shareAsPremiumBit?: string
  runtime?: BBDocRuntime | undefined
  backButtonPosition?: BBDocBackButtonPosition | undefined 
  description?: string  
}
export enum DownloadPlatforms {
  WINDOWS = "win",
  MAC = "mac",
  UNKNOWN = "unknown",
}

export interface BBDocResponseObj {
  id: string
  displayName: string
  bytes: number
  createdAt: number
  updatedAt: number
}

export interface IBBDownloadVersions {
  BB4: {
    WIN: string | undefined;
    MAC: string | undefined;
    APP: string;
  };
  BB3: {
    WIN: string | undefined;
    MAC: string | undefined;
    APP: string;
  };
  BB2: {
    WIN: string | undefined;
    MAC: string | undefined;
    APP: string;
  };
  Soundbox: {
    WIN: string | undefined;
    MAC: string | undefined;
    APP: string;
  }
}

export const BBDownloadVersion: IBBDownloadVersions = {
  BB4: {
    WIN: Env.BB4_DOWNLOAD_LINK_WIN,
    MAC: Env.BB4_DOWNLOAD_LINK_MAC,
    APP: "buildbox4",
  },
  BB3: {
    WIN: Env.BB3_DOWNLOAD_LINK_WIN,
    MAC: Env.BB3_DOWNLOAD_LINK_MAC,
    APP: "buildbox3",
  },
  BB2: {
    WIN: Env.BB2_DOWNLOAD_LINK_WIN,
    MAC: Env.BB2_DOWNLOAD_LINK_MAC,
    APP: "buildbox2",
  },
  Soundbox: {
    WIN: Env.SOUNDBOX_DOWNLOAD_LINK_WIN,
    MAC: Env.SOUNDBOX_DOWNLOAD_LINK_MAC,
    APP: "soundbox",
  }
};

  export interface PostSSOTokensDTO {
  accessToken: string
  refreshToken: string
  clientId: string
  clientSecret: string
}

export interface ChooseYourOwnAdventureChoiceData {
  choiceDescription: string
  coinsDelta: number
  healthDelta: number
  resultDescription: string
}

export interface ChooseYourOwnAdventureChapterData {
  description: string
  imageFilename: string
  choices: ChooseYourOwnAdventureChoiceData[]
}

export interface ChooseYourOwnAdventureCustomizationData {
  projectId: string
  badEndText: string
  goodEndText: string
  badEndImageFilename: string
  goodEndImageFilename: string
  title: string
  subtitle: string
  titleImageFilename: string
  backgroundMusicFilename: string
  iconFilename: string
  chapters: ChooseYourOwnAdventureChapterData[]
}

export enum AuthOption {
  LOGIN,
  SIGNUP,
}
 
 export enum bbaiStoryGamePromptStepStatus {
    INITIAL = "INITIAL",
    GENERATING_GAME_DATA = "GENERATING_GAME_DATA",
    GENERATING_GAME_ASSETS = "GENERATING_GAME_ASSETS",
    BUILD_BBDOC = "BUILD_BBDOC",
    DOWNLOAD_BBDOC = "DOWNLOAD_BBDOC",
    CLAIM_ACCOUNT = "CLAIM_ACCOUNT",
    COMPLETE = "COMPLETE",
    CLAIM_LOGIN_SKIP = "CLAIM_LOGIN_SKIP",
    LOGIN_ACCOUNT = "LOGIN_ACCOUNT",
    DONE = "DONE",
    GAME_DATA = "GAME_DATA",
    ERROR_RESTART_REGENERATE = "ERROR_RESTART_REGENERATE",
  }

export type AuthResult = {
  IdToken: string;
  AccessToken: string;
  RefreshToken: string;
  isGuest: boolean;
}