export function getRequiredEnvVar(envVarName: string, defaultVal?:string): string {
  const varName = `REACT_APP_${envVarName}`
  const val = process.env[varName]
  if (val === undefined) {
    if (defaultVal !== undefined) {
      return defaultVal
    }
    else {
      throw new Error(`[Env] config setting: ${varName} NOT FOUND`)
    }
  }
  return val
}

export function getRequiredIntEnvVal(envVarName:string, defaultVal:number): number {
  const strVal = getRequiredEnvVar(envVarName, defaultVal.toString(10))
  const numVal = parseInt(strVal, 10)
  if (isNaN(numVal)) {
    throw new Error(`[Env] config setting: REACT_APP_${envVarName} must be an integer value.`)
  }
  return numVal
}

export function getRequiredFloatEnvVal(envVarName:string, defaultVal:number): number {
  const strVal = getRequiredEnvVar(envVarName, defaultVal.toString(10))
  const numVal = parseFloat(strVal)
  if (isNaN(numVal)) {
    throw new Error(`[Env] config setting: REACT_APP_${envVarName} must be an integer value.`)
  }
  return numVal
}

export function getRequiredBoolEnvVal(envVarName:string, defaultVal:boolean): boolean {
  const strVal = getRequiredEnvVar(envVarName, ""+!!defaultVal).toLowerCase()
  if (strVal === "true" || strVal === "on" || strVal === "1" || strVal === "yes") {
    return true
  }
  else if (strVal === "false" || strVal === "off" || strVal === "0" || strVal === "no") {
    return false
  }
  else {
    throw new Error(`[Env] config setting: REACT_APP_${envVarName} must be a boolean value. got "${strVal}"`)
  }
}

/**
 * Return the appropriate value based on the existence of an env var with that name OR
 * one of the passed-in values depending on the value of AOB_ENV
 * 
 * @param overrideEnvVarName the name of the environment variable that would contain an override value WITHOUT the REACT_APP_ prefix. If a value is found, that value will be used instead of any values passed into this function
 * @param prodValue will be used if there is no environment variable found AND AOB_ENV === PROD OR AOB_ENV is not recognized
 * @param {string} devValue will be used if there is no environment variable found AND (AOB_ENV === DEV OR (AOB_ENV === LOCAL AND no localValue provided))
 * @param {string?} localValue optional - will be used if defined AND there is no environment variable found AND AOB_ENV === LOCAL
 * @returns 
 */
export function getEnvVarForEnvironment(overrideEnvVarName:string|null, prodValue:string, devValue:string, localValue?:string):string {
  let defaultVal:string
  const AOB_ENV = getRequiredEnvVar("AOB_ENV", "PROD").toUpperCase()
  if (AOB_ENV === "LOCAL") {
    if (localValue !== undefined) {
      defaultVal = localValue
    }
    else {
      defaultVal = devValue
    }
  }
  else if (AOB_ENV === "DEV") {
    defaultVal = devValue
  }
  else {
    if (AOB_ENV !== "PROD") {
      console.error(`[Env] Unrecognized environment name ${AOB_ENV} defaulting to PROD value`)
    }
    defaultVal = prodValue
  }
  if (overrideEnvVarName !== null) {
    return getRequiredEnvVar(overrideEnvVarName, defaultVal)
  }
  else {
    return defaultVal
  }
}

/**
 * A map of all the config / environment variables that this web app uses
 */
export const Env = {
  AOB_ENV: getRequiredEnvVar("AOB_ENV", "PROD"),

  // URLS
  BBAI_BASE_URL: getEnvVarForEnvironment("BBAI_BASE_URL", "https://ai.8cell.com", "https://ai.dev.8cell.com"),
  UAM_BASE_URL: getEnvVarForEnvironment("UAM_BASE_URL", "https://platform.buildbox.com", "https://useraccountmanagement.dev.8cell.com"),
  ASSET_MANAGER_BASE_URL: getEnvVarForEnvironment("ASSET_MANAGER_BASE_URL", "https://assetmanagementapi.buildbox.com", "https://assetmanagementservice.dev.8cell.com"),
  BB2D_BASE_URL: getEnvVarForEnvironment("BB2D_BASE_URL", "https://buildbox2service.buildbox.com", "https://buildbox2service.dev.8cell.com"),
//  BB2D_BASE_URL: getEnvVarForEnvironment("BB2D_BASE_URL", "https://buildbox2service.buildbox.com", "http://adam.dev.8cell.com:8102"),
  BB_WORLD_BASE_URL: getEnvVarForEnvironment("BB_WORLD_BASE_URL", "https://buildboxworldservice.buildbox.com", "https://buildboxworldservice.dev.8cell.com"),
  BB_DEEP_LINK: getEnvVarForEnvironment("BB_DEEP_LINK", "https://bbworld.page.link/bits?bit_id=","https://bbworlddev.page.link/bits?bit_id="),
  LEARN_MORE_URL: getEnvVarForEnvironment("LEARN_MORE_URL",  "https://signup.buildbox.com", "https://bbregister.dev.8cell.com",),
  VIEW_PLANS_URL: getEnvVarForEnvironment("VIEW_PLANS_URL", "https://signup.buildbox.com/plans", "https://bbregister.dev.8cell.com/plans" ),
  MY_ACCOUNT_URL: getEnvVarForEnvironment("MY_ACCOUNT_URL", "https://signup.buildbox.com/account/profile", "https://bbregister.dev.8cell.com/account/profile" ),
  BB4_DOWNLOAD_LINK_WIN: getEnvVarForEnvironment("BB4_DOWNLOAD_LINK_WIN", "https://releases.buildbox.com/buildbox/current/Buildbox4.exe", "https://releases.buildbox.com/buildbox/current/Buildbox4.exe"),
  BB4_DOWNLOAD_LINK_MAC: getEnvVarForEnvironment("BB4_DOWNLOAD_LINK_MAC", "https://releases.buildbox.com/buildbox/current/Buildbox4.dmg", "https://releases.buildbox.com/buildbox/current/Buildbox4.dmg"),
  BB3_DOWNLOAD_LINK_WIN: getEnvVarForEnvironment("BB3_DOWNLOAD_LINK_WIN", "https://releases.buildbox.com/buildbox/current/Buildbox3.exe", "https://releases.buildbox.com/buildbox/current/Buildbox3.exe"),
  BB3_DOWNLOAD_LINK_MAC: getEnvVarForEnvironment("BB3_DOWNLOAD_LINK_MAC", "https://releases.buildbox.com/buildbox/current/Buildbox3.dmg", "https://releases.buildbox.com/buildbox/current/Buildbox3.dmg"),
  BB2_DOWNLOAD_LINK_WIN: getEnvVarForEnvironment("BB2_DOWNLOAD_LINK_WIN", "https://releases.buildbox.com/buildbox/current/Buildbox2.exe", "https://releases.buildbox.com/buildbox/current/Buildbox2.exe"),
  BB2_DOWNLOAD_LINK_MAC: getEnvVarForEnvironment("BB2_DOWNLOAD_LINK_MAC", "https://releases.buildbox.com/buildbox/current/Buildbox2.dmg", "https://releases.buildbox.com/buildbox/current/Buildbox2.dmg"),
  SOUNDBOX_DOWNLOAD_LINK_WIN: getEnvVarForEnvironment("SOUNDBOX_DOWNLOAD_LINK_WIN", "https://downloads.buildbox.com/soundbox/current/Soundbox.exe", "https://downloads.buildbox.com/soundbox/current/Soundbox.exe"),
  SOUNDBOX_DOWNLOAD_LINK_MAC: getEnvVarForEnvironment("SOUNDBOX_DOWNLOAD_LINK_WIN", "https://downloads.buildbox.com/soundbox/current/Soundbox.dmg", "https://downloads.buildbox.com/soundbox/current/Soundbox.dmg"),
  APP_CLIENT_ID: getEnvVarForEnvironment("APP_CLIENT_ID", "5tamhcn07jpk28maluh9ss6hq2", "5gqatunj3j4o2j2tntk4t1ja4n"),
  GTM_ID: getEnvVarForEnvironment("GTM_ID", "GTM-K9XRLKZ", "GTM-K9XRLKZ"),
  QR_BB_WORLD: getEnvVarForEnvironment("QR_BB_WORLD", "https://frontend-assets.buildbox.com/deeplink-qr-bbworld.png", "https://frontend-assets.buildbox.com/deeplink-qr-bbworld-dev.png"),
  // config / settings
  ACCESS_TIMEOUT_MS: getRequiredIntEnvVal("ACCESS_TIMEOUT_MS", 60*60*1000), // 60 minutes *60 converts to seconds *1000 converts to ms
  DEBUG_MODE: getRequiredBoolEnvVal("DEBUG_MODE", false),
  CYOA_OPENAI_MODEL: getRequiredEnvVar("CYOA_OPENAI_MODEL", "gpt-4"),
  CYOA_OPENAI_TEMPERATURE: getRequiredFloatEnvVal("CYOA_OPENAI_TEMPERATURE", 0.7),

  // convenience methods
  get isLocal() {
    return this.AOB_ENV.toUpperCase() === "LOCAL"
  },
  get isDev() {
    return this.AOB_ENV.toUpperCase() === "DEV"
  },
  get isProd() {
    return this.AOB_ENV.toUpperCase() === "PROD"
  }
}

export default Env
