import React, { useState, useEffect } from "react";
import Log from "../src/utils/Log";
import { push } from "connected-react-router";
import QueryString from "query-string";
import routes from "./constants/routes.json";
import { Hub } from "@aws-amplify/core";
import { withRouter } from "react-router-dom";
import { redirectToLoginPage } from "./utils/routes";

import Header from "./containers/Header";
import Footer from "./containers/Footer";
import Routes from "./Routes";
import styles from "./styles/App.module.scss";

import BrowserRoadblock from "./containers/BrowserRoadblock";
//REDUX STUFF
import currentUserInfo from "./selectors/currentUserInfo";
import { login, logout } from "./actions/auth";
import { clientLoginRedirect, webLoginRedirect } from "./actions/current";
import { dispatchError } from "./actions/error";
import { showError } from "./actions/error";
import { setGAClientId } from "./actions/analytics";
import isLoggingInFromClient from "./selectors/isLoggingInFromClient";

import selectGAClientId from "./selectors/selectGAClientId";
import { waitForGAClientId } from "./utils/analyticsUtils";
import { setXsollaCookie } from "./utils/referralUtils";

import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import OneTrust from "./OneTrust";
import "react-toastify/dist/ReactToastify.css";

//use UserContext only for passing around methods:
export const UserContext = React.createContext();

function App(props) {
  const dispatch = useDispatch();

  const currentUser = useSelector(currentUserInfo);

  const [authError, setAuthError] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const errorState = useSelector((state) => state.error.error);
  const clientLoginState = useSelector(isLoggingInFromClient);
  const selectGoogleClientId = useSelector(selectGAClientId);

  useEffect(() => {
    onLoad();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorState) {
      Log.error(errorState, "App.js -- dispatching error");
      dispatch(dispatchError(errorState));
    }
    //eslint-disable-next-line
  }, [errorState]);

  // Subscribe to auth events
  useEffect(() => {
    const handler = async ({ payload }) => {
      switch (payload.event) {
        case "signIn":
          Log.info("", "sign in heard");
          try {
            await dispatch(login(props));
            const isFromBBClient = sessionStorage.getItem("fromBBClient");

            if (isFromBBClient === "true") {
              await dispatch(clientLoginRedirect());
            } else {
              await dispatch(webLoginRedirect(props));
            }

            setIsAuthenticated(true);
          } catch (e) {
            Log.error(e, "App.js: error caught signing in!!!");
            setAuthError(e);
            dispatch(showError(e.message));
          }
          break;

        case "signIn_failure":
          Log.error(payload.data, "App Component - error caught on signIn");

          if (payload.data.message.includes("invalid_grant") && !currentUser) {
            dispatch(
              showError("Something went wrong. Please sign out and log in.")
            );
            redirectToLoginPage(props);
          } else {
            // redirectToAuthUI(props, payload.data);
          }

          break;
        case "signOut":
          dispatch(push(routes.CLIENT_LOGOUT_REDIRECT));
          break;

        default:
          break;
      }
    };

    Hub.listen("auth", handler);
    
    //clean up any events
    return () => {
      Hub.remove("auth", handler);
    };
    //eslint-disable-next-line
  }, []);

  async function onLoad() {
    try {
      const currentUrl = new URL(window.location);
      const urlQuery = QueryString.parse(currentUrl.search);
      const referralShortCode = urlQuery["tracking_id"];

      if (referralShortCode) {
        setXsollaCookie(referralShortCode);
      }

      if (currentUser !== null) {
        Log.trace("user is already logged in");
      }
      if (!selectGoogleClientId) {
        await waitForGAClientId((googleClientId) =>
          dispatch(setGAClientId(googleClientId))
        );
      }
    } catch (e) {
      if (e !== "No current user") {
        Log.error(e, "App Component: error caught on load");
      }
    }
  }

  // window.onload = function () {
  //   console.log("hi");
  //   let sdk = document.getElementById("onetrust-consent-sdk");
  //   console.log("onetrust-consent-sdk", sdk);
  //   sdk.remove();
  // };

  async function handleLogout(url) {
    try {
      await dispatch(logout(url));
    } catch (e) {
      Log.error(e, "App Component: error caught logging out");
      dispatch(showError(e.message));
    }
  }

  const UserContextValue = {
    handleLogout: handleLogout,
    authError: authError,
    session: sessionStorage,
  };

  const hideHeaderAndFooter =
    props.location.pathname.includes("login") ||
    props.location.pathname === "/clientAwaitingGoogleLogin" ||
    props.location.pathname === "/clientAwaitingFacebookLogin" ||
    props.location.pathname === "/clientAwaitingAppleLogin" ||
    props.location.pathname === "/clientredirect" ||
    props.location.pathname === "/featurePostMessage" ||
    props.location.pathname === "/clientconnection" ||
    (props.location.pathname.includes("/account/") && clientLoginState) ||
    props.location.pathname === routes.REFERRAL_LANDING_PAGE;

  return (
    <div className={styles.App} id="App">
      <BrowserRoadblock>
        {!hideHeaderAndFooter && <OneTrust />}
        
        <ToastContainer autoClose={4000} />

        <UserContext.Provider value={UserContextValue}>
          {!hideHeaderAndFooter && (
            <Header appProps={{ isAuthenticated, props }} />
          )}
          <Routes appProps={{ isAuthenticated }} />
          {!hideHeaderAndFooter && <Footer />}
        </UserContext.Provider>
      </BrowserRoadblock>
    </div>
  );
}

export default withRouter(App);
