import React from "react";
import styles from "../styles/Footer.module.scss";
import classnames from "classnames"
import img from "../img/LogoBB_Normal.png";
// import { SocialText } from "../components/SocialIcons"

export default function Footer() {

  return (
    <div className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.linksContainer}>
          <div className={styles.linksWrapper}>
            <div className={styles.linkItems}>
              <a className={styles.logoWrapper} href="/">
                <img src={img} alt="logo" />
              </a>
              <label>STAY UP TO DATE</label>
              <form action="https://buildbox.us17.list-manage.com/subscribe/post?u=a8d5a261ccc1b19b96a0cde40&amp;id=e3bc94a36f&amp;f_id=0000c2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_self">
                <div id="mc_embed_signup_scroll">

                  <div className={classnames("mc-field-group", styles.formInputs)}>
                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required placeholder="Email Address" />
                    <button type="submit" value="Subscribe">Subscribe</button>
                  </div>
                  <div className="mc-field-group input-group">
                    <ul className={styles.interestsList}>
                      <li>
                        <input type="checkbox" value="4096" name="group[2156][4096]" id="mce-group[2156]-2156-0" />
                        <label htmlFor="mce-group[2156]-2156-0">Aspiring Game Developer</label>
                      </li>
                      <li>
                        <input type="checkbox" value="8192" name="group[2156][8192]" id="mce-group[2156]-2156-1" />
                        <label htmlFor="mce-group[2156]-2156-1">Creator</label>
                      </li>
                      <li>
                        <input type="checkbox" value="16384" name="group[2156][16384]" id="mce-group[2156]-2156-2" />
                        <label htmlFor="mce-group[2156]-2156-2">(Indie) Developer</label>
                      </li>
                      <li>
                        <input type="checkbox" value="32768" name="group[2156][32768]" id="mce-group[2156]-2156-3" />
                        <label htmlFor="mce-group[2156]-2156-3">New to Game Design</label>
                      </li>
                    </ul>
                    <span id="mce-group[2156]-HELPERTEXT" className="helper_text"></span>
                  </div>
                  <div hidden><input hidden name="tags" value="527" readOnly /></div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                    <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                  </div>
                  <div style={{position: "absolute", left: "-5000px"}} aria-hidden><input type="text" name="b_a8d5a261ccc1b19b96a0cde40_e3bc94a36f"  readOnly  /></div>
                  <div className="clear"></div>
                </div>
              </form>
            </div>  
          </div>
          <div className={styles.linkItemsSocial}>
            <div className={classnames(styles.socialText)}>
                <a href="https://www.buildbox.com/blog/"   target="_blank"
            rel="noopener noreferrer">About Us</a>
                <a href="/product/bb3">Products</a>
                <a href="/plans">Pricing</a>
                <a href="https://www.buildbox.com/contact/" target="_blank"
            rel="noopener noreferrer">Contact Us</a>
                {/*<a href="https://apponboard.com/careers/"  target="_blank"
            rel="noopener noreferrer">Careers</a>*/}

            </div>
            <div className={classnames(styles.socialText)}>
              <a
                href="https://www.facebook.com/buildboxofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://twitter.com/buildbox"
                target="_blank"
                rel="noopener noreferrer"
              >
              Twitter
              </a>
              <a
                href="https://www.youtube.com/c/buildbox?sub_confirmation=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </a>
              <a
                href="https://www.instagram.com/buildbox/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              <a
                href="https://discord.com/invite/buildbox"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </div>
          </div>
        </div>
        <div className={styles.termsContainer}>
          <p>©2021-2024 AppOnboard, Inc</p>
          <span>|</span>
          <a href="https://www.buildbox.com/privacy-policy/">Privacy Policy</a>
          <span>|</span>
          <a href="https://www.buildbox.com/terms/"> Terms</a>
        </div>
      </div>
    </div>
  );
}
