import { createSelector } from "reselect";
import { LoginClientType } from "../reducers/models";
import current from "./selectCurrentState";

export default createSelector(current, (currentState: bb.state.ICurrent) => {
  if (currentState.workState.client === LoginClientType.Client) {    
    return true;
  }
  return false;
});
