import Log from "./Log";
import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";
import { findCookieValue } from "./referralUtils";

export const checkTokenExpiration = async (token) => {
  if (token) {
    try {
      const parseJwt = JSON.parse(atob(token.split(".")[1]));

      const { exp } = await parseJwt;

      // get current epoch time
      const currentTimeStamp = new Date().getTime() / 1000;

      if (currentTimeStamp >= exp) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Log.error(error, "error caught in checkTokenExpiration");
      return null;
    }
  }
};

export const getCognitoUser = async () => {
  try {
    const isSocialSignReferral = sessionStorage.getItem("socialSignReferral");

    let refCodeId = null;

    if (isSocialSignReferral === "true") {
      refCodeId = await updateCustomField();
      sessionStorage.setItem("socialSignReferral", "");
    }

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.accessToken.jwtToken;
    const refreshToken = user.signInUserSession.refreshToken.token;
    const { email, name } = user.attributes;
    refCodeId =
      refCodeId === null
        ? user.attributes["custom:referralTrackingId"]
        : refCodeId;

    const userObject = {
      email,
      name,
      accessToken: token,
      refreshToken,
      referralTrackingId: refCodeId,
    };
    return userObject;
  } catch (e) {
    Log.info("error getting cognito user", e);
  }
};

async function updateCustomField() {
  let xsollaTrackingId = findCookieValue("tracking_id=");

  Log.info("checking for xsollaTrakcingId: ", xsollaTrackingId);

  if (xsollaTrackingId) {
    const user = await Auth.currentAuthenticatedUser();

    Log.info("adding custom attr -> ");

    try {
      await Auth.updateUserAttributes(user, {
        "custom:referralTrackingId": xsollaTrackingId,
      });
    } catch (err) {
      Log.info(" failed adding custom attr-> :( ", err);
    }

    Log.info(
      "added custom attr -> custom:referralTrackingId ",

      xsollaTrackingId
    );

    return xsollaTrackingId;
  } else {
    return null;
  }
}

export const getUserAttributes = async (accessToken) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser",
          "Content-Type": "application/x-amz-json-1.1",
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          AccessToken: accessToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        Log.info("data from getUserAttributes", data);
        const newObj = {};

        data.UserAttributes.forEach((obj) => {
          const values = Object.values(obj);
          const key = values[0];
          newObj[key] = values[1];
        });

        resolve({ username: data.Username, ...newObj });
      })
      .catch((error) => {
        Log.error(error, "error caught in while calling getUserAttributes");
        reject(error);
      });
  });
};

export const getRefreshedCognitoTokens = async (
  refreshToken,
  cognitoClientId,
  clientSecretHash
) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
          "Content-Type": "application/x-amz-json-1.1",
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          ClientId: cognitoClientId,
          AuthFlow: "REFRESH_TOKEN_AUTH",
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
            SECRET_HASH: clientSecretHash, // add client secret if there is one
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        Log.info("data from get refresh cognito tokens", data);

        if (!data.AuthenticationResult) {
          reject(data);
        }

        resolve(data.AuthenticationResult.AccessToken);
      })
      .catch((error) => {
        Log.error(error, "error caught in while calling REFRESH_TOKEN_AUTH");
        reject(error);
      });
  });
};
export const cognitoLogin = async (username, password, cognitoClientId) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
          "Content-Type": "application/x-amz-json-1.1",
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
          AuthFlow: "USER_PASSWORD_AUTH",
          AuthParameters: {
            PASSWORD: password,
            USERNAME: username,
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        Log.info("data from cognitoLogin", data);

        if (!data.AuthenticationResult) {
          reject(data);
        }
        resolve(data.AuthenticationResult);
      })
      .catch((error) => {
        Log.error(error, "error caught in while calling USER_PASSWORD_AUTH");
        reject(error);
      });
  });
};

export const updateUserAttributes = async (accessToken, userAttributes) => {
  const cognitoAttributesList = [];
  for (const key in userAttributes) {
    cognitoAttributesList.push({
      Name: key,
      Value: userAttributes[key],
    });
  }

  return new Promise((resolve, reject) => {
    fetch(
      `https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      {
        headers: {
          "X-Amz-Target":
            "AWSCognitoIdentityProviderService.UpdateUserAttributes",
          "Content-Type": "application/x-amz-json-1.1",
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          AccessToken: accessToken,
          UserAttributes: cognitoAttributesList,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error({
            status: response.status,
            message: response.statusText,
          });
        }
        return response.json();
      })
      .then((data) => {
        resolve({ status: 200, body: cognitoAttributesList });
      })
      .catch((error) => {
        Log.error(error, "error caught in while calling updateUserAttributes");
        reject(error);
      });
  });
};

export const changeCognitoPassword = async (
  accessToken,
  previousPassword,
  newPassword
) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.ChangePassword",
          "Content-Type": "application/x-amz-json-1.1",
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          AccessToken: accessToken,
          PreviousPassword: previousPassword,
          ProposedPassword: newPassword,
        }),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error({
            status: response.status,
            message: response.statusText,
          });
        }
        return response.json();
      })
      .then((data) => {
        resolve({ status: 200, body: "password successfully changed!" });
      })
      .catch((error) => {
        Log.error(error, "error caught in while calling changeCognitoPassword");
        reject(error);
      });
  });
};
export const refreshWebAccessToken = async () => {
  Log.info("", "refreshWebAccessToken called");

  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      (error, session) => {
        if (error) {
          Log.error(error, "error caught in while refreshing web client token");
          reject(error);
        }
        const accessToken = session.accessToken.jwtToken;
        //console.log("accessToken", accessToken);

        resolve(accessToken);
      }
    );
  });
};

export const confirmAccount = (cognitoClientId, userName, confirmationCode) => {
  return new Promise((resolve, reject) => {
    API.post("userAccountManagementURL", "/confirmCognitoAccount", {
      body: {
        clientId: cognitoClientId,
        userName: userName,
        confirmationCode: confirmationCode,
      },
      headers: {
        "Content-Type": "application/json",
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserSessionTokens = async () => {
  try {
    const currentSession = await Auth.currentSession();

    if (currentSession) {
      return true;
    }

    return false;
  } catch (e) {
    Log.error(e, "error: getUserSessionTokens");
    throw e;
  }
};
