import React, { FunctionComponent } from "react";
// import useNewCYOAStore from "../../../newCYOAStore";
import styles from "../../../styles/PromptMessage.module.css";
import { useHistory } from "react-router-dom";

interface LoginPromptProps {
}
const LoginPrompt: FunctionComponent<LoginPromptProps> = () => {
    const history = useHistory();
    const handleLogin = () => {

        history.push("/login")
    }
    return (
        <>
            <p>
                To continue, <span className={styles.fancyText} onClick={() => handleLogin()}>Create an Account</span> or <span className={styles.fancyText} onClick={() => handleLogin()}>Log-In</span>
            </p>
        </>
    );
}

export default LoginPrompt;