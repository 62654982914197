import API from "@aws-amplify/api";
import Log from "../utils/Log";
import { isMobileOnly } from "react-device-detect";

/**
 * Attempts to charge a user and change their subscription type
 * If the charge is successful they will then be rebilled via stripe.
 * @param {stripePaymentId}: string
 * @param {stripePlanId}: string
 * @param {token}: string
 * @param {totalSeats}: number
 * @param {totalCost}: number
 * @param {saleCode}: string
 */
export async function updateSubscription (
  token: string,
  productType: string,
  stripePaymentId: string,
  stripePlanId: string,
  totalSeats: number ,
  totalCost: number | null ,
  saleCode: string | null,
  name: string,
  email: string
): Promise<any> {

  try{
    const params = {
      stripePaymentId: stripePaymentId,
      stripePlanId: stripePlanId,
      totalSeats: totalSeats,
      totalCost: totalCost,
      saleCode: saleCode,
      name,
      userEmail: email
    };

    if(productType === null){
      throw Error("productType is not defined")
    }
    const endpoint = getProductEndpoint(productType.toUpperCase())

    const res = await API.put("plansURL", endpoint, {
        body: params,
        headers: {
          "Content-Type": "application/json",
          Token: token,
        },
        response: true,
      })

    return res.data;

  } catch(error: any){

    if (error.response) {
      console.error("error has been hit when updating subscription", error.response.data.error)
      throw Error(error.response.data.error);
    }else {
      console.error("error has been hit when updating subscription", error)
      throw Error(error);
    }
  }

};

/**
 * Returns list of all of the subscription types and their features
 * @param {token}: string
 */

export async function getPlans(token:string):Promise<any> {
  try{
    const res = await API.get("userAccountManagementURL", "/subscriptiontypes", {
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
      response: true,
    })

    return res.data
  } catch(error: any) {
    if (error.response) {
      console.error("error has been hit when getting plans", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else{
       console.error("error has been hit when getting plans", error)
      throw Error(error);
    }
  }
};

/**
 * Returns information about specific subscription plan
 * @param {subscriptionTypeId}: number
 * Not ready yet
 */

export async function getPlanInfo (token: string, subscriptionTypeId:string):Promise<any> {
  try{
    const res = await API.get("userAccountManagementURL", `subscription/${subscriptionTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
      response: true,
    })

    return res.data
  }catch(error: any){
    if(error.response){
      Log.error("error has been hit when getting plan info", error.response.data.error)
      throw Error(error.response.data.error)
    } else {
      Log.error("error has been hit when getting plan info", error)
      throw Error(error)
    }
  }
};

/**
 * Returns list of all of the subscription types and their features
 * @param {token}: string
 */
export async function getUserSubscriptionProviders (token: string): Promise<bb.model.account.IUserPlanProviders>{
  try{
    const res = await API.get("plansURL", "/user/planProviders", {
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
      response: true,
    })
    
    return res.data.payload
  }catch(error:any) {
    if(error.response){
      Log.error("error has been hit when checking user plan", error.response.data.error)
      return error.response.data.error
    } else {
      // fatal error...
      Log.error("error has been hit when checking user plan", error)
      // return error
      throw error
    }
  }
};

/**
 * reNews the subscription
 * @param {stripePaymentId}: string
 * @param {stripePlanId}: string
 * @param {token}: string
 * @param {totalSeats}: number
 * @param {totalCost}: number
 * @param {saleCode}: string
 */
export async function renewSubscription (
  token: string,
  productType: string,
  stripePaymentId: string,
): Promise<any>{
  try{
    const renewPaymentBody = {
       stripePaymentId: stripePaymentId,
    }

    if(productType === null){
      throw Error("productType is not defined")
    }
    const endpoint =  getProductEndpoint(productType.toUpperCase())

    const res = await API.post("plansURL", `${endpoint}/renew`, {
        body: renewPaymentBody,
        headers: {
          "Content-Type": "application/json",
          Token: token,
        },
        response: true,
      }) 

    return res.data;  

  } catch(error: any){

    if (error.response) {
      Log.error("error has been hit when renewing subscription", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
      Log.error("error has been hit when renewing subscription", error)
      throw Error(error);
    }
  }
};

/**
 * reNews the subscription
 * @param {stripePaymentId}: string
 * @param {stripePlanId}: string
 * @param {token}: string
 * @param {totalSeats}: number
 * @param {totalCost}: number
 * @param {saleCode}: string
 */
export async function accountlessUpdateSubscription (
  productType: string,
  stripePaymentId: string,
  stripePlanId: string,
  totalSeats: number,
  totalCost: number | null,
  saleCode: string | null,
  name: string,
  email: string
): Promise<any> {

  try{
    const params = {
      stripePaymentId: stripePaymentId,
      stripePlanId: stripePlanId,
      totalSeats: totalSeats,
      totalCost: totalCost,
      saleCode: saleCode,
      name,
      userEmail: email
    };

    if(productType === null){
      throw Error("productType is not defined")
    }
    const endpoint = getProductEndpoint(productType.toUpperCase())

    const res = await API.post("plansURL", endpoint, {
        body: params,
        headers: {
          "Content-Type": "application/json",
        },
        response: true,
      })

    return res.data;  

  } catch(error: any){

    if (error.response) {
     Log.error("error has been hit when accountless subscription update", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
     Log.error("error has been hit when accountless subscription update", error)
      throw error;
    }
  }
    
};


function getProductEndpoint(productType: string): string {
  let endpoint = ""

  switch (productType) {
    case 'BB2':
      endpoint = `/users/planbb2`
      break
    case 'BB3':
      endpoint = `/users/planbb3`
      break
    case 'BB4':
      endpoint = `/users/planbb4`
      break
    case 'BBBUNDLE':
      endpoint = `/users/bundleplan`
      break
    case 'SOUNDBOX':
      endpoint = `/users/plansoundbox`
      break
    default:
      throw Error(`[stripe purchase] Unknown product type ${productType}`)
  }

  return endpoint
}


/**
 * Start a stripe checkout session
 */
export async function startStripeCheckoutSession (
  token: string|undefined,
  embedded: boolean,
  stripePriceId: string,
  quantity: number=1,
  productType: string,
  planType: string,
  parentSessionId: string|null,
  email?: string,
  couponCode?: string,
): Promise<any>{
  try{
    const dtoBody : any = {
      priceId: stripePriceId,
      quantity: quantity,
      productType: productType,
      planType: planType,
      email: email,
      couponCode: couponCode,
      embedded: embedded,
    }
    if(parentSessionId) {
      dtoBody.upsellFromPreviousSessionId = parentSessionId
    }
    
    let headers
    let url 
    if(token) {
      url = "/stripecheckout"
      headers = {
        "Content-Type": "application/json",
        Token: token,
      }
    }
    else {
      url = "/stripecheckoutguest"
      headers = {
        "Content-Type": "application/json",
      }
    }
    const res = await API.post("plansURL", url, {
        body: dtoBody,
        headers: headers,
        response: true,
    })

    return res.data;  
  } catch(error: any){
    console.log("failed to start stripe checkout session ", error)    
    throw error
  }
};

/**
 * Retrieve a stripe checkout session; note that this endpoint nests the session object and 
 * also includes information about whether we think the user needs to complete registration
 * @param {sessionId}: string
 */
export async function retrieveStripeCheckoutSessionFromPlatform (
  sessionId: string,
): Promise<any>{
  try{    
    const url = "/stripecheckout/" + sessionId
    const headers = {
      "Content-Type": "application/json",
    }
    const res = await API.get("plansURL", url, {
        headers: headers,
        response: true,
      })

    return res.data;  
  } catch(error: any){
    console.log("failed to retrieve stripe checkout session ", error)
    
    if (error.response) {
      Log.error("error has been hit when retrieving stripe checkout session", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
      Log.error("error has been hit when retrieving stripe checkout session", error)
      throw Error(error);
    }
  }
};

export async function requestStripePurchaseEmailForSessionId(
  stripeCheckoutSessionId: string
): Promise<any> {
  try{
    const url = "/stripecheckout/sendSessionPurchaseEmail"
    const headers = {
      "Content-Type": "application/json",
    }
    const res = await API.post("plansURL", url, {
        body: {
          stripeCheckoutSessionId: stripeCheckoutSessionId
        },
        headers: headers,
        response: true,
      })

    return res.data;  
  } catch(error: any){
    console.log("failed to send stripe purchase email ", error)
    
    if (error.response) {
      Log.error("error has been hit when sending stripe purchase email", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
      Log.error("error has been hit when sending stripe purchase email", error)
      throw Error(error);
    }
  }
};



/**
 * Start an xsolla checkout (paystation) session
 */
export async function getXsollaPaystationTokenForSubscription (
  token: string|undefined,
  xsollaSku: string,
  xsollaProductId: string,
  productType: string,
  planType: string,
  parentSessionId: string|null,
  userEmail?: string,
): Promise<any>{
  try{
    let deviceType = "desktop";
    if (isMobileOnly) {
      deviceType = "mobile";
    }

    const dtoBody : any = {
      sku: xsollaSku,
      purchaseType: "subscription",
      deviceType: deviceType,
      productType: productType,
      planType: planType,
      webhook: true,
    }
    if(parentSessionId) {
      dtoBody.upsellFromPreviousSessionId = parentSessionId
    }
    
    let headers
    let url 
    if(token) {
      url = "/paystation/checkouturl"
      headers = {
        "Content-Type": "application/json",
        Token: token,
      }
    }
    else {
      url = "/paystation/checkouturlguest"
      headers = {
        "Content-Type": "application/json",
      }
    }


    if (userEmail) {
      dtoBody.email = userEmail;
    } 
    if (xsollaProductId) {
      dtoBody.productId = "" + xsollaProductId; // xsolla wants a string now
    }

    const res = await API.post("plansURL", url, {
      body: dtoBody,
      headers: headers,
      response: true,
    })

    return res.data;  
  } catch(error: any){
    console.log("failed to start xsolla paystation checkout session ", error)    
    throw error
  }
};

/**
 * Retrieve an xsolla checkout/invoice/transaction session; note that this endpoint nests the xsolla object info and 
 * also includes information about whether we think the user needs to complete registration
 * @param {invoiceId}: string
 */
export async function retrieveXsollaCheckoutSessionFromPlatform (
  sessionId: string,
): Promise<any>{
  try{    
    const url = "/xsollacheckout/" + sessionId
    const headers = {
      "Content-Type": "application/json",
    }
    const res = await API.get("plansURL", url, {
        headers: headers,
        response: true,
      })

    return res.data;  
  } catch(error: any){
    console.log("failed to retrieve xsolla checkout session ", error)
    
    if (error.response) {
      Log.error("error has been hit when retrieving xsolla checkout session", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
      Log.error("error has been hit when retrieving xsolla checkout session", error)
      throw Error(error);
    }
  }
};

// not fully implemented, turned out to work better on the platform side webhook anyway
// export async function requestXsollaPurchaseEmail(
//   invoiceId: string,
// ): Promise<any> {
//   try{
//     const url = "/xsollacheckout/sendSessionPurchaseEmail"
//     const headers = {
//       "Content-Type": "application/json",
//     }
//     const res = await API.post("plansURL", url, {
//         body: {
//           invoiceId: invoiceId,
//         },
//         headers: headers,
//         response: true,
//       })

//     return res.data;  
//   } catch(error: any){
//     console.log("failed to send xsolla purchase email ", error)
    
//     if (error.response) {
//       Log.error("error has been hit when sending xsolla purchase email", error.response.data.error)
//       throw Error(error.response.data.error);
//     }
//     else {
//       Log.error("error has been hit when sending xsolla purchase email", error)
//       throw Error(error);
//     }
//   }
// };