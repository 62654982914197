import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import auth from "./authReducer";
import account from "./accountReducer";
import sale from "./saleReducer";
import error from "./errorReducer";
import cart from "./cartReducer";
import current from "./currentReducer";
import monetization from "./monetizationReducer";
import  storyGame from "./storyGameReducer";
import { persistReducer } from "redux-persist";
import sessionStorage from 'redux-persist/lib/storage/session'

const sessionStorageStoryGamePersistConfig = {
  key: 'storygame',
  storage: sessionStorage,
}

export default function createRootReducer(history: History) {
  return combineReducers({
    auth: auth,
    account: account,
    cart: cart,
    sale: sale,
    error: error,
    current: current,
    monetization: monetization,
    router: connectRouter(history),
    storyGame: persistReducer(sessionStorageStoryGamePersistConfig, storyGame),
    // storyGame: storyGame,
  });
}
