import React, { FunctionComponent, useEffect} from "react";
import styles from "../../../styles/PromptMessage.module.css";
import { Row, Col } from "antd";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  AssetType,
  bbaiStoryGamePromptStepStatus,
} from "../constants/bbai.prompts";
import CircularProgress from "./CircularProgress";
import useInfiniteProgress from "../../../hooks/useInfiniteProgress";
import { TypeAnimation } from 'react-type-animation';

export interface MessageProps {
  message: string;
  user: {
    id: string;
    pic: string;
  };
  // handleSelectedAsset?: (img: string, assetType: string, idx: number) => void;
  assetType?: AssetType | bbaiStoryGamePromptStepStatus;
  promptStage:  bbaiStoryGamePromptStepStatus;
  handleDoneTyping?: () => void;
  loading?: boolean | null;
  successReport?: boolean
  component?: JSX.Element;
  error?: boolean
  completed?: boolean
}

const PromptMessage: FunctionComponent<MessageProps> = ({
  message = "",
  user,
  promptStage,
  loading = false,
  successReport = false,
  component,
  error = false,
  handleDoneTyping,
  completed = false,
}) => {

  const progressValue = useInfiniteProgress({successReport})
  useEffect(() => {

  }, [message, user]);

  const checkDoneTypingShowLoad = () => {
  return !error && loading
  }

  return (
    <>
      <Row
        className={`${styles.promptMessage} ${
          user.id === "1"
            ? styles.promptFeedbackBBAI
            : styles.promptFeedbackUser
        }`}
      >
        <Col xs={4} sm={3} md={3} lg={2} xl={2}>
          <img className={styles.senderImg} src={user.pic} alt="sender" />
        </Col>
        <Col xs={20} sm={21} md={21} lg={22} xl={22}>
          { component? component :  <> 
           {user.id === "1" ? <TypeAnimation sequence={[`${message}`]}  wrapper="span"  speed={50} cursor={false}/> : <>{message}</>} {""} {checkDoneTypingShowLoad()  &&  <CircularProgress progress={progressValue} />} </>
          }
        </Col>
      </Row>
    </>
  );
};

export default PromptMessage;
