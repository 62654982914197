import API from "@aws-amplify/api";
import Log from "../utils/Log";

/**
 * fetches users plans
 * @param {token}: string
 * @returns {ICurrentPlan[]}
 */

/**
 * 
 * @param {string} accessToken 
 * @returns {Promise<bb.model.account.IStripePlans}
 */
export const fetchUserPlan = (accessToken) => {
  return new Promise((resolve, reject) => {
    API.get("plansURL", "/users/plans", {
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(res.data, "SUCCESS - fetchUserPlan response:");
          resolve(res.data.payload);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in fetchUserPlan");
          reject(error);
        }
      });
  });
};

/**
 * fetches user billing info
 * @param {token}: string
 * @returns {ISubscriptionInfo[]}
 */

export const fetchBillingInfo = (accessToken) => {
  return new Promise((resolve, reject) => {
    API.get("userAccountManagementURL", "/billing/info", {
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(res.data, "SUCCESS - fetchBillingInfo response:");
          resolve(res.data.payload.payload);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in fetchBillingInfo");
          reject(error);
        }
      });
  });
};

/**
 * check if user is eligible for a trial upsell
 * @param {accessToken}: string
 * @returns {UpsellData}
 */

export const fetchTrialUpsellInfo = (accessToken, product, planType) => {
  return new Promise((resolve, reject) => {
    let endpoint
    let headers
    if(accessToken){
      if(planType === "Pro") {
        endpoint = `/trialUpsellInfo/ProToBundle`;        
      }
      else {
        endpoint = `/trialUpsellInfo/${product}`;
      }
      headers = {
        "Content-Type": "application/json",
        Token: accessToken,
      }
    }
    else {
      if(planType === "Pro") {
        endpoint = `/trialUpsellInfoGuest/ProToBundle`;
      }
      else {
        endpoint = `/trialUpsellInfoGuest/${product}`;
      }
      headers = {
        "Content-Type": "application/json",
      }
    }
    API.get("userAccountManagementURL", endpoint, {
        headers: headers,
        response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(res.data, "SUCCESS - fetchTrialUpsellInfo response:");
          resolve(res.data.payload);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in fetchTrialUpsellInfo");
          reject(error);
        }
      });
  });
};

/**
 * updates user billing info
 * @param {token}: string
 * @param {paymentProcessor}: string
 * @param {subscriptionId}: string
 * @param {stripePaymentId}: string
 */

export const updateBillingInfo = (
  accessToken,
  paymentProcessor,
  subscriptionId,
  stripePaymentId
) => {
  return new Promise((resolve, reject) => {
    API.put("userAccountManagementURL", `/billing/${paymentProcessor}/update`, {
      body: {
        subscriptionId: subscriptionId,
        stripePaymentId: stripePaymentId,
      },
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(res.data, "SUCCESS - updateBillingInfo response:");
          resolve(res.data.payload);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in updateBillingInfo");
          reject(error);
        }
      });
  });
};

/**
 * fetches user devices
 * @param {accessToken}: string
 * @param {userId}: string
 * @returns {Promise<{ [key: string]: bb.model.account.IDevices[] }>}
 */
export const fetchUserDevices = (accessToken, userId) => {
  return new Promise((resolve, reject) => {
    API.get("userAccountManagementURL", `/users/${userId}/allmachines`, {
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(res.data, "SUCCESS - fetchUserDevices response:");
          resolve(res.data.payload);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in fetchUserDevices");
          reject(error);
        }
      });
  });
};

/**
 * updates user device status
 * @param {accessToken}: string
 * @param {userId}: string
 * @param {macHash}: string
 * @returns {ISubscriptionInfo[]}
 */

export const updateDeviceStatus = (accessToken, userId, active, macHash, productId) => {
  return new Promise((resolve, reject) => {
    API.put("userAccountManagementURL", `/users/${userId}/updateMachineStatus`, {
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      body: {
        Active: active,
        MACHash: macHash,
        ProductId: productId,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(res.data, "SUCCESS - updateDeviceStatus response:");
          resolve(res.data.payload);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in updateDeviceStatus");
          reject(error);
        }
      });
  });
};

/**
 * 
 * @param {string} accessToken 
 *  @param {string } userId
 * @returns {Promise<bb.model.account.IEligibleRenewal>}
 */
export const fetchUserEligibleRenewal = (accessToken, userId) => {
  return new Promise((resolve, reject) => {
    API.get("plansURL", `/renewEligibility/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.payload);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error when finding eligible renewal plans");
          reject(error);
        }
      });
  });
};

export const addUserToWaitlist = (userInfo) => {
  return new Promise((resolve, reject) => {
    API.post("userAccountManagementURL", "/waitlist", {
      headers: {
        "Content-Type": "application/json",
      },
      body: userInfo,
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          Log.info(res.data, "SUCCESS - addUserToWaitlist response:");
          resolve(res.data);
        }
      })
      .catch((error) => {
        if (error) {
          Log.error(error, "error caught in addUserToWaitlist");
          reject(error);
        }
      });
  });
}
