import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router";
import Amplify from "@aws-amplify/core";
import ChunkErrorBoundary from "./containers/ChunkErrorBoundary";

import { aws_config } from "./constants/amplify_config";
// import TagManager from "react-gtm-module";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PersistGate } from "redux-persist/integration/react";

import configureStore, { history } from "./configureStore";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

// const tagManagerArgs = {
//   gtmId: "GTM-K8KCBNX", // Google Tag Manager ID gets injected into react pages
// };

// TagManager.initialize(tagManagerArgs);

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: aws_config.cognito.REGION,
    userPoolId: aws_config.cognito.USER_POOL_ID,
    userPoolWebClientId: aws_config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: aws_config.s3.REGION,
    bucket: aws_config.s3.BUCKET,
  },
  API: {
    endpoints: [
      {
        name: "userAccountManagementURL",
        endpoint: aws_config.apiGateway.URL,
        region: aws_config.apiGateway.REGION,
      },
      {
        name: "plansURL",
        endpoint: aws_config.plansGateway.URL,
      },
      {
        name: "adsURL",
        endpoint: aws_config.adManagerGateway.URL,
      },
      {
        name: "assetsURL",
        endpoint: aws_config.assetManagementGateway.URL,
      },
      {
        name: "bbworldUrl",
        endpoint: aws_config.bbworldGetway.URL,
        region: aws_config.apiGateway.REGION,
      },
      {
        name: "pseudoURL",
        endpoint: aws_config.pseudo.URL,
      },
      {
        name: "bb2URL",
        endpoint: aws_config.bb2Gateway.URL,
      },
      {
        name: "bbaiURL",
        endpoint: aws_config.bbaiGateway.URL,
      },
      {
        name: "assetManagerURL",
        endpoint: aws_config.assetMangerGateway.URL,
      }
    ],
  },
  oauth: {
    domain: aws_config.oauth.domain,
    scope: aws_config.oauth.scope,
    redirectSignIn: aws_config.oauth.redirectSignIn,
    redirectSignOut: aws_config.oauth.redirectSignOut,
    responseType: aws_config.oauth.responseType,
  },
});

const { store, persistor } = configureStore();

ReactDOM.render(
  <ChunkErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ChunkErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
