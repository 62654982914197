import { LoginClientType, LoginState, WorkState, ProductType } from "./models";
import { AnyAction } from "redux";
import actions from "../constants/actions.json";

const initialState: bb.state.ICurrent = {
  fromSSORedirect: false,
  workState: {
    client: LoginClientType.Web,
    loginState: LoginState.None,
    analytics: WorkState.None,
    signup: WorkState.None,
    purchase: WorkState.None,
    planFetch: WorkState.None,
    billingInfoFetch: WorkState.None,
    invoicesFetch: WorkState.None,
    transactionsFetch: WorkState.None,
    projectsFetch: WorkState.None,
    adSettings: WorkState.None,
  },
  userState: {
    productType: ProductType.BB4,
  },
};

const currentReducer = (
  state: bb.state.ICurrent = initialState,
  action: AnyAction
): bb.state.ICurrent => {
  switch (action.type) {
    case actions.current.SET_LOGIN_STATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          loginState: action.loginState,
        },
      };
    case actions.current.SET_SIGNUP_STATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          signup: action.workState,
        },
      };
    case actions.current.SET_ANALYTICS_WORKSTATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          analytics: action.workState,
        },
      };
    case actions.current.SET_PURCHASE_STATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          purchase: action.workState,
        },
      };
    case actions.current.SET_PLAN_FETCH_STATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          planFetch: action.workState,
        },
      };
    case actions.current.SET_BILLING_INFO_FETCH_STATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          billingInfoFetch: action.workState,
        },
      };
    case actions.current.SET_INVOICES_FETCH_STATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          invoicesFetch: action.workState,
        },
      };
    case actions.current.SET_TRANSACTIONS_FETCH_WORKSTATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          transactionsFetch: action.workState,
        },
      };
    case actions.current.SET_PROJECTS_FETCH_WORKSTATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          projectsFetch: action.workState,
        },
      };
    case actions.current.SET_AD_SETTINGS_WORKSTATE:
      return {
        ...state,
        workState: {
          ...state.workState,
          adSettings: action.adSettingsWorkState,
        },
      };
    case actions.current.SET_LOGIN_TYPE:
      return {
        ...state,
        workState: {
          ...state.workState,
          client: action.client,
        },
      };
    case actions.current.SET_FROM_SSO_REDIRECT:
      return {
        ...state,
        fromSSORedirect: action.fromSSORedirect,
      };
    case actions.current.SET_PRODUCT_TYPE:
      const productType = action.productType.toUpperCase();
      sessionStorage.setItem("productType", productType);
      sessionStorage.setItem("websocket-product", productType);

      return {
        ...state,
        userState: {
          ...state.userState,
          productType: productType,
        },
      };
    case actions.current.RESET_CURRENT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default currentReducer;
