import { createBrowserHistory, History } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers/";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { getBrowser } from "./utils/browserUtils";
import { persistStore, persistReducer } from "redux-persist";

export const history: History = createBrowserHistory();

const currentBrowser = getBrowser();

let persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "account", "cart", "current", "error"],
  blacklist: ["sale", "router"],
};

if (currentBrowser === "Safari") {
  persistConfig = {
    key: "root",
    storage: storageSession,
    whitelist: ["auth", "account", "cart", "current", "error"],
    blacklist: ["sale", "router", ],
  };
}

export default function configureStore(initialState?: bb.state.IRedux) {
  const middlewares = [routerMiddleware(history), thunk];

  const enhancers = [applyMiddleware(...middlewares)];

  let composeEnhancer = compose;

  if (process.env.REACT_APP_STAGE === "dev") {
    composeEnhancer =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const reducers = createRootReducer(history);

  // Middleware: Redux Persist Persisted Reducer
  const persistedReducer = persistReducer(persistConfig, reducers);

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancer(...enhancers) as any 
  );

  // Middleware: Redux Persist Persisted Store
  const persistor = persistStore(store);

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      store.replaceReducer(require("./reducers").default);
    });
  }

  return { store, persistor };
}
