export enum SignUpWorkState {
  Loading = "Loading",
  Conflict = "Conflict",
  None = "None",
  ServerError = "ServerError",
}

export enum LoginState {
  Loading = "Loading",
  Unauthorized = "Unauthorized",
  None = "None",
  ServerError = "ServerError",
}

export enum LoginClientType {
  Web = "Web",
  Client = "Client",
}

export enum WorkState {
  Loading = "Loading",
  Error = "Error",
  None = "None",
  Success = "Success",
}

export enum Toast {
  HIDE_ERROR = "HIDE_ERROR",
  SHOW_ERROR = "SHOW_ERROR",
}

export enum DownloadPlatforms {
  WINDOWS = "win",
  MAC = "mac",
  UNKNOWN = "unknown",
}

export enum ProductCategory {
  Monthly = "Monthly",
  BB2 = "BB2",
  BB3 = "BB3",
  BB4 = "BB4",
  BBBundle = "BBBundle",
  Soundbox = "Soundbox"
}
export function stringToProductCategory(str:string|undefined):ProductCategory|null {
  if (str === undefined) {
    return null
  }
  switch(str.toLowerCase()) {
    case ProductCategory.Monthly.toLowerCase():
      return ProductCategory.Monthly
    case ProductCategory.BB2.toLowerCase():
      return ProductCategory.BB2
    case ProductCategory.BB3.toLowerCase():
      return ProductCategory.BB3
    case ProductCategory.BB4.toLowerCase():
      return ProductCategory.BB4
    case ProductCategory.Soundbox.toLowerCase():
      return ProductCategory.Soundbox
    case ProductCategory.BBBundle.toLowerCase():
      return ProductCategory.BBBundle
    default:
      return null
  }
}

export enum ProductType {
  BB2 = "BB2",
  BB3 = "BB3",
  BB4 = "BB4",
  BBBundle = "BBBundle",
  Soundbox = "Soundbox",
}

export enum PlanType {
  Monthly = "Monthly", // this one is weird but legit since there's a specific "Monthly" tier for BB2
  Free = "Free",
  Plus = "Plus",
  Pro = "Pro",
  Bundle = "Bundle",
  //Weekly = "Weekly", // fixed/migrated to 'Beginner'
  Beginner = "Beginner",
  Basic = "Basic",
}

export enum PlanIds {
  BB4Free = "BB4Free",
  //BB4Weekly = "BB4Weekly",
  BB4Basic = "BB4Basic",
  BB4Pro = "BB4Pro",
  BB4TrialUpsell = "BB4TrialUpsell",
  BB4Beginner = "BB4Beginner",
  BB3Free = "BB3Free",
  BB3Monthly = "BB3Monthly",
  BB3Plus = "BB3Plus",
  BB3Pro = "BB3Pro",
  BB3TrialUpsell = "BB3TrialUpsell",
  BB3Beginner = "BB3Beginner",
  BB2Free = "BB2Free",
  BB2Monthly = "BB2Monthly",
  BB2Plus = "BB2Plus",
  BB2Pro = "BB2Pro",
  BB2Beginner = "BB2Beginner",
  BB2TrialUpsell = "BB2TrialUpsell",
  BBBundle = "BBBundle",
  BBBundleUpsell = "BBBundleUpsell",
  SoundboxFree = "SoundboxFree",
  SoundboxPro = "SoundboxPro",
}

export enum PlanInterval {
  Monthly = "Monthly",
  Yearly = "Yearly",
  Weekly = "Weekly",
  Lifetime = "Lifetime",
}

export enum PaymentPlatforms {
  Stripe = "stripe",
  Xsolla = "xsolla"
}

export enum AppPlatforms {
  iOS = "iOS",
  Android = "Android",
}

export enum AdPaymentDescription {
  AUTOMATED = "AUTOMATED",
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
}

export enum HWAccountStatus {
  UNREGISTERED = "UNREGISTERED",
  PRE_ACTIVATED = "PRE_ACTIVATED",
  ACTIVATED = "ACTIVATED",
  LOCKED = "LOCKED",
  FROZEN = "FROZEN",
  DE_ACTIVATED = "DE_ACTIVATED",
}

export enum HWVerificationStatus {
  NOT_REQUIRED = "NOT_REQUIRED",
  REQUIRED = "REQUIRED",
  FAILED = "FAILED",
  UNDER_REVIEW = "UNDER_REVIEW",
  VERIFIED = "VERIFIED",
}

export enum BBVersions {
  BUILDBOX2 = "BB2",
  BUILDBOX3 = "BB3",
  BUILDBOX4 = "BB4",
  BUILDBOXWORLD = "BBWorld",  
  GENERIC = "generic", 
  SOUNDBOX = "Soundbox",
}

export const PlanNameToPlanIds: Record<string, PlanIds> = {
  "Buildbox 4 Free": PlanIds.BB4Free,
  //"Buildbox 4 Weekly": PlanIds.BB4Weekly,
  "Buildbox 4 Basic": PlanIds.BB4Basic,
  "Buildbox 4 Pro": PlanIds.BB4Pro,
  "Buildbox 4 Beginner": PlanIds.BB4Beginner,
  "Buildbox 3 Free": PlanIds.BB3Free,
  "Buildbox 3 Plus": PlanIds.BB3Plus,
  "Buildbox 3 Pro": PlanIds.BB3Pro,
  "Buildbox Classic Free": PlanIds.BB2Free,
  "Buildbox Classic Plus": PlanIds.BB2Plus,
  "Buildbox Classic Pro": PlanIds.BB2Pro,
  "Buildbox Classic Beginner": PlanIds.BB2Beginner,
  "Buildbox Bundle": PlanIds.BBBundle,
  "Soundbox Free": PlanIds.SoundboxFree,
  "Soundbox Pro": PlanIds.SoundboxPro,
}
