

export const BBAI_STORE_VERSION = 6;

export const storeStorageVersion = (storeVersion: number, storageKey: string ) => {
  const BBAI_STORE_VERSION_KEY = `${storageKey}-version`;
  
  const storedVersionStr = localStorage.getItem(BBAI_STORE_VERSION_KEY);
  const storedVersion: number =
    storedVersionStr !== null ? parseInt(storedVersionStr) : 0;
  if (isNaN(storedVersion) || storedVersion < storeVersion) {
    console.warn(
      `[store.ts] Detected older version of prompt storage schema - clearing existing state`
    );
    localStorage.removeItem(storageKey);
    localStorage.setItem(BBAI_STORE_VERSION_KEY, storeVersion.toString(10));
  } else {
    console.debug(
      `[store.ts] bbai-storage-version is valid. found: ${storedVersion}, minimum: ${storeVersion}`
    );
  }

}

