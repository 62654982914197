import Log from "./Log";

//used to grab google client id to track users for analytic events

export const getGAClientId = () => {
  return new Promise((resolve, reject) => {
    if ("ga" in window && window.ga && window.ga.getAll) {
      try {
        const trackers = window.ga.getAll();
        const trackingId = window.ga.getAll()[0].get("trackingId");
        for (let i = 0; i < trackers.length; i++) {
          if (trackers[i].get("trackingId") === trackingId) {
            const googleClientId = trackers[i].get("clientId");
            //Log.info(googleClientId, "got GA client id");

            localStorage.setItem("googleClientId", googleClientId);
            resolve(googleClientId);
          }
        }
      } catch (e) {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

let gaCheckInterval = 100; // milliseconds
let gaTimeElapsed = -1 * gaCheckInterval; // first check comes for free
let gaMaxWait = 10000; // milliseconds
export const waitForGAClientId = async (cb) => {
  try {
    const googleClientId = await getGAClientId();

    if (!googleClientId) {
      gaTimeElapsed += gaCheckInterval;
      if (gaTimeElapsed >= gaMaxWait) {
        Log.trace("waited too long for GA client id");
        cb(null);
      } else {
        setTimeout(() => waitForGAClientId(cb), gaCheckInterval);
      }
    } else {
      await cb(googleClientId);
    }
  } catch (e) {
    console.warn(e, "waitforGAclientID()");
  }
};
