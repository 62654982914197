import React from 'react'
import { Col, Row } from 'antd'
import { bbaiUser } from '../constants/bbai.prompts'
import styles from "../../../styles/PromptMessage.module.css";
// import currentStoryGame from "../../../selectors/currentStoryGame";
import { generateTokenForSSO } from '../../../utils/CognitoHelper';
import Env from '../../../Env';

const ErrorOutOfCreditsCYOA = () => {

  const navigateTo = (url: string) => {
     window.location.replace(url);
  }

  const setTokenQuery = async (baseUrl: string) => {

    console.log("WE are here lets goo")
    const token = await generateTokenForSSO()
    
    const urltoken = token === ""? baseUrl : `${baseUrl}?token=${token}`

    console.debug("setTokenQuery", urltoken)
    navigateTo(urltoken);
  }
  
  return (
    <>
      <Row
        className={`${styles.promptMessage} ${ styles.promptFeedbackBBAI}`}
      >
        <Col xs={4} sm={3} md={3} lg={2} xl={2}>
          <img className={styles.senderImg} src={bbaiUser.pic} alt="sender" />
        </Col>
        <Col xs={20} sm={21} md={21} lg={22} xl={22}>
          <p>
            Our AI loved making free StoryGames for you! They are ready to make more for you once you upgrade to any paid plan by tapping <a href='/#' onClick={() => setTokenQuery(Env.VIEW_PLANS_URL as string)}>HERE</a>
          </p>
        </Col>
      </Row>
    </>
  )
}

export default ErrorOutOfCreditsCYOA