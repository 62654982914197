import React,{ ReactElement, useEffect, useState } from 'react'
import { bbaiStoryGamePromptOptions, bbaiStoryGamePromptStepStatus, bbaiUser, defaultUser } from '../constants/bbai.prompts'
import PromptMessage from './PromptMessage'
import currentStoryGame from "../../../selectors/currentStoryGame";
import LoginPrompt from './LoginPrompt'
// import { BBPlatformClient } from '../../../api/BBPlatform'
import ErrorOutOfCreditsCYOA from './ErrorOutOfCreditsCYOA'
import { useDispatch, useSelector } from 'react-redux';
import { nextStage, setErrorState } from '../../../actions/storyGame';
import { validateToken } from '../../../actions/auth';
import { getBalance } from '../../../api/storyGame';
import currentUserInfo from '../../../selectors/currentUserInfo';

const InitialCYOAPrompt = (): ReactElement  => {
  const [userStoryPrompt, setUserStoryPrompt] = useState<string>("")
  const [notEnoughCreditsError, setNotEnoughCreditsError] = useState<boolean>(false)

  const bbaiStore = useSelector(currentStoryGame)
  const userStore = useSelector(currentUserInfo)

  const dispatch = useDispatch()
  
  useEffect(() => {
    if(bbaiStore.userStoryPrompt.length > 0){
      setUserStoryPrompt(bbaiStore.userStoryPrompt)
      if( userStore && !bbaiStore.completed){
        CheckIfUserHasEnoughCredits()
      }
    } else {
      setUserStoryPrompt("")
    }
    // eslint-disable-next-line
  }, [bbaiStore.userStoryPrompt, notEnoughCreditsError, bbaiStore.gameDataRestartID])

  const CheckIfUserHasEnoughCredits = async() => {
    try{
      
      const refreshedToken = await dispatch(validateToken());
      const userCreditsInfo = await getBalance(refreshedToken)
      console.log("userCreditsInfo", userCreditsInfo )
      if (userCreditsInfo.balance < 1) {
        setNotEnoughCreditsError(true)
  
        // bbaiStore.setPromptStepState(bbaiStoryGamePromptStepStatus.ERROR_RESTART_REGENERATE)  
      }
  
      dispatch(nextStage())

    }catch(error){
      console.error("error checking user credits", error)
       dispatch(setErrorState({
          title: "Error ran out of Story Game credts",
          message: "You have run out of Story Game credits. Please purchase more credits to continue.",
        }));
    }
  }

  return (
    <>
    <PromptMessage promptStage={bbaiStoryGamePromptStepStatus.INITIAL} message={bbaiStoryGamePromptOptions.INITIAL.prompt} user={bbaiUser}
    completed={bbaiStore.completed}
    />

    { userStoryPrompt.length > 0 && <PromptMessage promptStage={bbaiStoryGamePromptStepStatus.INITIAL} message={userStoryPrompt} user={defaultUser} completed={bbaiStore.completed}
      />
      
    }
    {notEnoughCreditsError && <ErrorOutOfCreditsCYOA/> }

    { userStoryPrompt.length > 0 && !userStore && <PromptMessage promptStage={bbaiStoryGamePromptStepStatus.INITIAL} component={<LoginPrompt />} message={""} user={bbaiUser}
    completed={bbaiStore.completed}
    /> }
    </>
  )
}

export default InitialCYOAPrompt