import { WorkState } from "./../reducers/models";
import {
  setPurchaseState,
  setPlanFetchWorkState,
  setInvoicesFetchWorkState,
} from "./current";
import Log from "../utils/Log";
import actions from "../constants/actions.json";
import { DefaultThunkAction } from "./shared";
import { AnyAction } from "redux";
import { showError } from "./error";
import { 
  accountlessUpdateSubscription, 
  getUserSubscriptionProviders, 
  getXsollaPaystationTokenForSubscription, 
  renewSubscription,
  startStripeCheckoutSession, 
  updateSubscription 
} from "../api/subscription";
import {
  fetchUserPlan,
  // fetchBillingInfo,
  updateBillingInfo,
  fetchUserDevices,
  fetchUserEligibleRenewal,
  addUserToWaitlist
} from "../api/account";
import { validateToken } from "./auth";
import { setAnalyticsPurchaseEvent } from "./analytics";
import { mapToFreePlan } from "../utils/accountUtils";
import { formatUNIXTimestamp } from "../utils/helperUtils";
import { analyticProductTypes } from "../shared/utility";
import { WaitlistFormFields } from "../components/home/WaitlistForm";

export function beginXsollaCheckoutSession(email: string|undefined, parentSessionId: string|null): DefaultThunkAction<Promise<object>> {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { cart } = getState();

    dispatch(setPurchaseState(WorkState.Loading));
    try {
      if(!email && auth.user) {
        console.log("using email from logged in user: ", auth.user.email)
        email = auth.user.email
      }
      else if(email) {
        console.log("using email from passed in email: ", email)
      }
      else {
        console.log("no email available, will be guest checkout session")
      }

      if(cart.items.length !== 1) {
        throw new Error("Plans must be purchased one at a time and your cart contains " + cart.items.length + " items, if this is unexpected please contact support at support@buildbox.com.");
      }

      const { item } = cart.items[0];
      // get xsolla plan id and product id from plan info
      let xsollaProductId = null;
      let xsollaPlanId;
      if (process.env.REACT_APP_XSOLLA_ENV === "production") {
        xsollaPlanId = item.xsollaId[item.planInterval];
        xsollaProductId = item.xsollaProductId;
      } 
      else {
        xsollaPlanId = item.xsollaIdDev[item.planInterval];
        xsollaProductId = item.xsollaProductIdDev;
      }

      const { productType, planType, /*analyticsTierName*/ } = item;
      sessionStorage.setItem("productType", productType);

      let refreshedToken
      if(auth?.user?.uuid) {
        refreshedToken =  await dispatch(validateToken()) 
      }
      const response = await getXsollaPaystationTokenForSubscription(
        refreshedToken,
        xsollaPlanId,
        xsollaProductId,
        productType,
        planType,
        parentSessionId,
        email,
      );

      dispatch(setPurchaseState(WorkState.None));
      return response;
    } 
    catch (error:any) {
      console.log("account got error beginXsollaCheckoutSession", error)
      dispatch(setPurchaseState(WorkState.Error));
      throw error;
    }
  };
}

export function beginStripeCheckoutSession(
  embedded: boolean,
  quantity: number,
  parentSessionId: string|null, // used to connect steps during Upsell flow
  couponCode?: string,
): DefaultThunkAction<Promise<object>> {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { cart } = getState();

    dispatch(setPurchaseState(WorkState.Loading));
    try {
      let email

      if(auth.user) {
        email = auth.user.email
      }

      if(cart.items.length !== 1) {
        throw new Error("Plans must be purchased one at a time and your cart contains " + cart.items.length + " items, if this is unexpected please contact support at support@buildbox.com.");
      }

      const { item } = cart.items[0];
      let stripePlanId;
      if (process.env.REACT_APP_STAGE === "production") {
        stripePlanId = item.stripeId[item.planInterval];
      }
      else {
        stripePlanId = item.stripeIdDev[item.planInterval];
      }

      // if item doesn't allow discounts, remove any coupon code
      // if we send it to stripe we can get a "coupon does not apply" error from Stripe Checkout
      if(!item.discountsAllowed) {
        couponCode = undefined;
      }      

      const { productType, planType, /*analyticsTierName*/ } = item;
      sessionStorage.setItem("productType", productType);

      let refreshedToken
      if(auth?.user?.uuid) {
        refreshedToken =  await dispatch(validateToken()) 
      }
      const sessionResponse = await startStripeCheckoutSession(
        refreshedToken,
        embedded,
        stripePlanId,
        quantity,
        productType,
        planType,
        parentSessionId,
        email,
        couponCode,
      );

      dispatch(setPurchaseState(WorkState.None));
      return sessionResponse;
    } catch (error:any) {
      console.log("account got error beginStripeCheckoutSession", error)
      dispatch(setPurchaseState(WorkState.Error));
      throw error;
    }
  };
}

// AP: kinda weird that apparently Stripe purchases come here but Xsolla purchases don't
export function purchasePlan(
  stripePaymentId: string,
  quantity: number,
  purchaseCost: number | null,
  saleCode: string | null,
  name: string,
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { cart } = getState();

    dispatch(setPurchaseState(WorkState.Loading));
    try {
      let email
      let uuid
      
      if(!auth?.user?.uuid ){
        email = auth.accountlessPurchaseEmail
      } 
      else {
        email = auth?.user?.email;
        uuid = auth?.user?.uuid;
      }

      if(cart.items.length !== 1) {
        throw new Error("Plans must be purchased one at a time and your cart contains " + cart.items.length + " items, if this is unexpected please contact support.");
      }

      const { item } = cart.items[0];
      let stripePlanId;
      if (process.env.REACT_APP_STAGE === "production") {
        stripePlanId = item.stripeId[item.planInterval];
      }
      else {
        stripePlanId = item.stripeIdDev[item.planInterval];
      }

      const { productType, analyticsTierName } = item;

      // AP: quickfix try for an error where productType wasn't available in sessionStorage;
      //  more importantly though I'm adding productType just into the function params, duh
      const sessionProductType = sessionStorage.getItem("productType");
      if(productType && !sessionProductType) {
        sessionStorage.setItem("productType", productType);
      }

      if(uuid) {
        const refreshedToken =  await dispatch(validateToken()) 
        await updateSubscription(
          refreshedToken,
          productType,
          stripePaymentId,
          stripePlanId,
          quantity,
          purchaseCost,
          saleCode,
          name,
          email
        );
      }
      else {
        await accountlessUpdateSubscription(
          productType,
          stripePaymentId,
          stripePlanId,
          quantity,
          purchaseCost,
          saleCode,
          name,
          email
        );
      }

      const product = analyticProductTypes[productType];

      //send analytics purchase request
      await dispatch(
        setAnalyticsPurchaseEvent(email, uuid, analyticsTierName, item.planInterval, product)
      );

      dispatch(setPurchaseState(WorkState.Success));
    } catch (error:any) {
      Log.error(error, "Error dispatching action: purchasePlan");
      dispatch(setPurchaseState(WorkState.Error));

      throw error;
    }
  };
}

export function renewPlan(
  stripePaymentId: string,
  quantity: number,
  purchaseCost: number | null,
  saleCode: string | null,
  name: string,
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { cart } = getState();

    dispatch(setPurchaseState(WorkState.Loading));
    try {
      //TODO: how do we handle multiple plan versions in one cart in the future
      let email = ""
      let uuid = ""
      
      if(!auth?.user?.uuid ){
        email = auth.accountlessPurchaseEmail
      } else {
        email = auth?.user?.email;
        uuid = auth?.user?.uuid;

      }

      const { item } = cart.items[0];
      let stripePlanId;
      if (process.env.REACT_APP_STAGE === "production") {
        stripePlanId = item.stripeId[item.planInterval];
      }
      else {
        stripePlanId = item.stripeIdDev[item.planInterval];
      }

      const { productType, analyticsTierName } = item;

      if (!uuid || !stripePaymentId || !item || !item.planInterval || !stripePlanId) {
        dispatch(setPurchaseState(WorkState.Error));

        Log.error(
          `Found empty value for: id: ${stripePaymentId}, uuid: ${uuid}, plan interval: ${item.planInterval}, item: ${item}, stripeplanid: ${stripePlanId}`,
          "Missing values in renew Plan action"
        );
        throw new Error(
          "Sorry, something went wrong. You have not been charged. Please return to signup.buildbox.com/login and try again."
        );
      }

      const refreshedToken = await dispatch(validateToken());

      const subscriptionUpdateResponse = await renewSubscription(
          refreshedToken,
          productType,
          stripePaymentId,
        );
     
      const product = analyticProductTypes[productType];

      dispatch(setUpcomingInvoice(subscriptionUpdateResponse.payload))
    
      //send analytics purchase request
      await dispatch(
        setAnalyticsPurchaseEvent(email, uuid, analyticsTierName, item.planInterval, product)
      );

      dispatch(setPurchaseState(WorkState.Success));
    } catch (error:any) {
      Log.error(error, "Error dispatching action: purchasePlan");
      dispatch(setPurchaseState(WorkState.Error));

      throw error;
    }
  };
}

export function fetchAccountData(): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    await dispatch(fetchCurrentPlans());
  };
}

//fetches the current user plan info and sets it into account reducer
export function fetchCurrentPlans(): DefaultThunkAction<Promise<void>> {
  // accessToken: string
  return async (dispatch, getState) => {
    const { auth } = getState();
    dispatch(setPlanFetchWorkState(WorkState.Loading));
    try {
      if (auth == null) {
        throw new Error(
          "We can't get your plan info because you are not logged in."
        );
      }

      const refreshedToken = ((await dispatch(
        validateToken()
      )) as unknown) as string;

      let planData: bb.model.account.IStripePlans;
      // let subs;
      let devices: { [key: string]: bb.model.account.IDevices[] } = {};
      let eligibleRenewal: bb.model.account.IEligibleRenewal = {
        bb2: false,
        bb3: false,
        bb4: false,
        bbbundle: false,
        soundbox: false
      };

      try {
        planData = await fetchUserPlan(refreshedToken);
      } catch (error:any) {
        Log.trace(error, "Error fetching billing info");
        throw new Error("We had an issue with looking up your plan info.");
      }

      // TODO re-enable this when the endpoint is fixed to account for Xsolla
      //      it can be ommitted for now because we don't currently display any of this info
      // try {
      //   // @ts-ignore
      //   subs = await fetchBillingInfo(refreshedToken);
      // } catch (error:any) {
      //   Log.trace(error, "Error fetching billing info");
      // }

      try {
        devices = await fetchUserDevices(refreshedToken, auth.user.uuid);
      } catch (error:any) {
        Log.trace(error, "Error fetching machine data");
        throw new Error(
          "We had an issue with looking up your subscription seat usage."
        );
      }

      try {
        eligibleRenewal = await fetchUserEligibleRenewal(refreshedToken, auth.user.uuid)
      }catch (error) {
        Log.trace(error, "Error fetch User Eligible Renewal");
        throw new Error(
          "We had an issue with looking up your renewal eligibility."
        );
      }
      // const invoices = subs.invoices;
      let planInfo: bb.model.account.ICurrentPlan[];
      // TODO re-enable this when the endpoint is fixed to account for Xsolla
      //      it can be ommitted for now because we don't currently display any of this info
      // if (subs.subscriptionInfo.length === 0) {
      planInfo = mapToFreePlan(planData, devices);
      // } else {
      //   planInfo = mapToPlan(
      //     subs.subscriptionInfo,
      //     planData,
      //     invoices,
      //     devices
      //   );
      // }

      dispatch(setCurrentPlans(planInfo));

      dispatch(setEligibleRenewal(eligibleRenewal))

      // await dispatch(fetchInvoices(invoices));
      dispatch(setPlanFetchWorkState(WorkState.None));
    } catch (e:any) {
      Log.error(e, "Error dispatching: fetchCurrentPlan");
      dispatch(setPlanFetchWorkState(WorkState.Error));

      if (e) {
        let message = e.message
        dispatch(showError(message));
      } else {
        dispatch(
          showError("There was an error fetching your current plan information")
        );
      }
    }
  };
}

//fetches invoices on /account/subscription page

export function fetchInvoices(
  invoices: bb.model.account.IStripeInvoice[]
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(setInvoicesFetchWorkState(WorkState.Loading));
    try {
      //TODO: call real invoices endpoint here

      const invoicesInfo: bb.model.account.IInvoice[] = invoices.map(
        (invoice) => {
          return {
            id: invoice.id,
            description: invoice.description,
            paidAt: formatUNIXTimestamp(invoice.paidAt * 1000),
            currency: invoice.currency.toUpperCase(),
            amountPaid: (invoice.amountPaid / 100).toString(),
            pdfUrl: invoice.pdfUrl,
          };
        }
      );

      dispatch(setInvoices(invoicesInfo));

      dispatch(setInvoicesFetchWorkState(WorkState.None));
    } catch (e:any) {
      dispatch(setInvoices([]));
      dispatch(setInvoicesFetchWorkState(WorkState.Error));
    }
  };
}

// updates billing info on /account/subscription/billing page

export function updateBilling(
  subscriptionId: string,
  paymentId: string
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    getState();
    dispatch(setPlanFetchWorkState(WorkState.Loading));
    try {
      const refreshedToken = await dispatch(validateToken());

      await updateBillingInfo(
        refreshedToken,
        "stripe",
        subscriptionId,
        paymentId
      );
    } catch (error:any) {
      let message = error.message
      dispatch(showError(message));
    }
  };
}

// add user to waitlist
export function addWaitlistUser(
  userInfo: WaitlistFormFields
): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    // const refreshedToken = ((await dispatch(
    //   validateToken()
    // )) as unknown) as string;

    try {
      await addUserToWaitlist(userInfo);
    } catch (error:any) {
      Log.error(error, "Error dispatching: addWaitlistUser");
      dispatch(showError("There was an error adding you to the waitlist"));
    }
  }
}

export function fetchPlanProvider (): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    try{ 
      const refreshedToken = await dispatch(validateToken());

      const planData = await fetchUserPlan(refreshedToken);
      const userPlanProviders = await getUserSubscriptionProviders(
        refreshedToken
      );

      const plansAndProviders: bb.model.account.IPlans = {
        bb2: {
          plan: planData.BB2Plan,
          provider: userPlanProviders.bb2
        },
        bb3: {
          plan: planData.BB3Plan,
          provider: userPlanProviders.bb3
        },
        bb4: {
          plan: planData.BB4Plan,
          provider: userPlanProviders.bb4
        },
        bbbundle: {
          plan: null, // not sure if need or want
          provider: userPlanProviders.bbbundle
        },
        soundbox: {
          plan: planData.SoundboxPlan,
          provider: userPlanProviders.soundbox
        }
      }
      
      dispatch(setUserPlanProviders(userPlanProviders));
      dispatch(setUserPlans(plansAndProviders));

    } catch(error: any) {
      Log.error(error, "Error dispatching: fetchPlanProvider");
      dispatch(showError("There was an error fetching the plan provider"));
    }
  }
}

export function setCurrentPlans(
  plans: bb.model.account.ICurrentPlan[]
): AnyAction {
  return {
    type: actions.account.SET_CURRENT_PLANS,
    plans,
  };
}

export function setInvoices(invoices: bb.model.account.IInvoice[]): AnyAction {
  return {
    type: actions.account.SET_INVOICES,
    invoices,
  };
}

export function clearAccount() {
  return {
    type: actions.account.CLEAR_ACCOUNT,
  };
}

export function setUpcomingInvoice(upcomingInvoice: bb.model.account.IUpcomingInvoice): AnyAction {
  return {
    type: actions.account.SET_UPCOMING_INVOICE,
    upcomingInvoice,
  };
}

export function setBundleSubscription(bundleSubscription: boolean ) {
  return {
    type: actions.account.SET_BUNDLE_SUBSCRIPTION,
    bundleSubscription
  };
}

export function setEligibleRenewal(eligibleRenewal: bb.model.account.IEligibleRenewal) {
  return {
    type: actions.account.SET_ELIGIBLE_RENEWAL,
    eligibleRenewal
  }
}

export function setUserPlanProviders(planProviders: bb.model.account.IUserPlanProviders) {
  return {
    type: actions.account.SET_PLAN_PROVIDERS,
    planProviders
  }
}

export function setUserPlans(plans: bb.model.account.IPlans) {
  return {
    type: actions.account.SET_USER_PLANS,
    plans
  }
}