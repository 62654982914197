import classnames from "classnames"
import React, { ReactElement } from "react"

import styles from "../styles/SocialIcons.module.scss"

export function SocialIcons(props:{className?:string}): ReactElement {
  return (
    <div className={classnames(styles.socialIconsContainer, props.className)}>
      <a
        className={styles.socialLink}
        href="https://www.facebook.com/buildboxofficial"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"https://frontend-assets.buildbox.com/Facebook.svg"}
          alt="Buildbox on Facebook"
        />
      </a>
      <a
        className={styles.socialLink}
        href="https://twitter.com/buildbox"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"https://frontend-assets.buildbox.com/Twitter.svg"}
          alt="Buildbox Twitter / X"
        />
      </a>
      <a
        className={styles.socialLink}
        href="https://www.youtube.com/c/buildbox?sub_confirmation=1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"https://frontend-assets.buildbox.com/YouTube.svg"}
          alt="Buildbox on Youtube"
        />
      </a>
      <a
        className={styles.socialLink}
        href="https://www.instagram.com/buildbox/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"https://frontend-assets.buildbox.com/Instagram.svg"}
          alt="Buildbox on Instagram"
        />
      </a>
      <a
        className={styles.socialLink}
        href="https://discord.com/invite/buildbox"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"https://frontend-assets.buildbox.com/Discord.svg"}
          alt="Buildbox Discord"
        />
      </a>
    </div>
  )
}


export function SocialText(props:{className?:string}): ReactElement {
  return (
    <div className={classnames(props.className)}>
      <a
        className={styles.socialLinkVertical}
        href="https://www.facebook.com/buildboxofficial"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>
      <a
        className={styles.socialLinkVertical}
        href="https://twitter.com/buildbox"
        target="_blank"
        rel="noopener noreferrer"
      >
       Twitter
      </a>
      <a
        className={styles.socialLinkVertical}
        href="https://www.youtube.com/c/buildbox?sub_confirmation=1"
        target="_blank"
        rel="noopener noreferrer"
      >
        Youtube
      </a>
      <a
        className={styles.socialLinkVertical}
        href="https://www.instagram.com/buildbox/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>
      <a
        className={styles.socialLinkVertical}
        href="https://discord.com/invite/buildbox"
        target="_blank"
        rel="noopener noreferrer"
      >
        Discord
      </a>
    </div>
  )
}