import {
  isIE,
  isChrome,
  isSafari,
  isFirefox,
  isMobileOnly,
  isTablet,
  isBrowser,
} from "react-device-detect";

export const isUnsupportedBrowser = () => {
  if (isIE) {
    return true;
  } else {
    return false;
  }
};

export const getBrowser = () => {
  if (isIE) {
    return "IE";
  } else if (isFirefox){
    return "Firefox"
  } else if (isSafari) {
    return "Safari";
  } else if (isChrome) {
    return "Chrome";
  }
};

export const getDeviceType = () => {
  if (isMobileOnly) {
    return "mobile";
  } else if (isTablet) {
    return "tablet";
  } else if (isBrowser) {
    return "desktop";
  }
  return null;
};

export const isMobileDevice = () => {
  return isMobileOnly;
};
