import React, { ReactNode } from 'react';

interface ChunkErrorBoundaryProps {
  children: ReactNode;
}

interface ChunkErrorBoundaryState {
  hasChunkError: boolean;
  hasOtherError: boolean;
}

class ChunkErrorBoundary extends React.Component<ChunkErrorBoundaryProps, ChunkErrorBoundaryState> {
  constructor(props: ChunkErrorBoundaryProps) {
    super(props);
    this.state = { 
      hasChunkError: false,
      hasOtherError: false,
    };
  }

  static getDerivedStateFromError(error: Error): Partial<ChunkErrorBoundaryState> | null {
    if (error.name === "ChunkLoadError" || error.message.includes("MIME type")) {
      return { hasChunkError: true, hasOtherError: false };
    }
    else {
      return { hasChunkError: false, hasOtherError: true };
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (error.name === "ChunkLoadError" || error.message.includes("MIME type")) {
      console.error("Chunk or MIME type error caught in ChunkErrorBoundary:", error, info);
    }
    else {
      console.error("Other error caught in ChunkErrorBoundary:", error, info);
    }
  }

  render() {
    if (this.state.hasChunkError) {
      return (
        <div style={styles.container}>
          <h2 style={styles.title}>This page has been updated!</h2>
          <p style={styles.message}>
            Please refresh to get the latest version.
          </p>
          <button style={styles.button} onClick={() => window.location.reload()}>
            Refresh Page
          </button>
        </div>
      );
    }
    else if (this.state.hasOtherError) {
      return (
        <div style={styles.container}>
          <h2 style={styles.title}>Something unexpected happened.</h2>
          <p style={styles.message}>
            You can try refreshing the page to try again. If you need more assistance please contact support@buildbox.com
          </p>
          <button style={styles.button} onClick={() => window.location.reload()}>
            Refresh Page
          </button>
        </div>
      );

    }

    return this.props.children;
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    textAlign: 'center' as const,
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '16px',
  },
  message: {
    fontSize: '16px',
    color: '#555',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default ChunkErrorBoundary;
